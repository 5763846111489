import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import styles from './Accordion.module.scss';

interface IAccordion {
  title: string;
  text: string;
  analyticsCallback?: (str: string) => any;
}

const Accordion: React.FunctionComponent<IAccordion> = ({ title, text, analyticsCallback }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    if (!isExpanded && analyticsCallback) {
      analyticsCallback(title);
    }

    setIsExpanded(!isExpanded);
  };

  return (
    <div className={styles.component} aria-expanded={isExpanded}>
      <button className={styles.title} onClick={handleClick}>
        {title}
      </button>

      <AnimatePresence initial={false}>
        {isExpanded && (
          <motion.div
            initial={'collapsed'}
            animate={'expanded'}
            exit={'collapsed'}
            variants={{
              expanded: { height: 'auto', transition: { type: 'spring', stiffness: 200, mass: 0.25, damping: 20 } },
              collapsed: { height: 0, transition: { type: 'spring', stiffness: 200, mass: 0.1, damping: 10 } },
            }}
            className={styles.wrapper}>
            <div className={styles.text} dangerouslySetInnerHTML={{__html: text}}/>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Accordion;
