import React from 'react';
import classNames from 'classnames';

import styles from './ScrollWrapper.module.scss';

interface IScrollWrapper {
  lock?: boolean;
}

const ScrollWrapper: React.FunctionComponent<IScrollWrapper> = ({ children, lock = false }) => {
  const componentClass = classNames(styles.component, {
    [styles.isLocked]: lock,
  });

  return <div className={componentClass}>{children}</div>;
};

export default ScrollWrapper;
