import React from 'react';
import { MotionRouter, MotionSwitch, MotionRoute } from 'features/motion-router';
import styles from './App.module.scss';

import Navigation from 'components/Navigation';
import ConsentModal from 'components/ConsentModal';
// import ResolutionNotice from './ResolutionNotice';
import DataLoader from './DataLoader';

import LunchPage from 'pages/Lunch';
import InfoPage from 'pages/Info';
import LunchABCPage from 'pages/Info/LunchABC';
import OpeningTimesPage from 'pages/Info/OpeningTimes';
import PricingPage from 'pages/Info/Pricing';
import FAQPage from 'pages/Info/FAQ';
import PrivacyPage from 'pages/Info/Privacy';
import FeedbackPage from 'pages/Info/Feedback';
import SettingsPage from 'pages/Settings';
import FoodsPage from 'pages/Settings/Foods';
import RestaurantsPage from 'pages/Settings/Restaurants';
import LanguagePage from 'pages/Settings/Language';

const App: React.FunctionComponent = () => {
  return (
    <div className={styles.component}>
      <DataLoader />

      <MotionRouter>
        <div className={styles.main}>
          <MotionSwitch>
            <MotionRoute exact path="/info">
              <InfoPage />
            </MotionRoute>
            <MotionRoute exact path="/">
              <LunchPage />
            </MotionRoute>
            <MotionRoute exact path="/asetukset">
              <SettingsPage />
            </MotionRoute>
            <MotionRoute path="/info/lounaan-abc">
              <LunchABCPage />
            </MotionRoute>
            <MotionRoute path="/info/aukioloajat">
              <OpeningTimesPage />
            </MotionRoute>
            <MotionRoute path="/info/hinnasto">
              <PricingPage />
            </MotionRoute>
            <MotionRoute path="/info/usein-kysyttya">
              <FAQPage />
            </MotionRoute>
            <MotionRoute path="/info/yksityisyys">
              <PrivacyPage />
            </MotionRoute>
            <MotionRoute path="/info/palaute">
              <FeedbackPage />
            </MotionRoute>
            <MotionRoute path="/asetukset/ruokavalio">
              <FoodsPage />
            </MotionRoute>
            <MotionRoute path="/asetukset/ravintolat">
              <RestaurantsPage />
            </MotionRoute>
            <MotionRoute path="/asetukset/kieli">
              <LanguagePage />
            </MotionRoute>
          </MotionSwitch>
        </div>
        <div className={styles.footer}>
          <Navigation />
        </div>
        <ConsentModal />
      </MotionRouter>

      {/* <ResolutionNotice /> */}
    </div>
  );
};

export default App;
