import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';
import { IRestaurant } from 'pages/Lunch/Restaurant';
import { IFood } from 'pages/Lunch/Food';
import { IDisplayMode } from 'pages/Lunch/RestaurantList';
import { getRestaurantData } from '../data/selectors';

export const checkIsRestaurantCollapsed =
  (state: RootState) =>
  (id: number): boolean =>
    state.restaurants.collapsed.includes(id);
export const getFilteredRestaurants = (state: RootState): number[] => state.restaurants.filtered;

export const checkShowRestaurantDetails = (state: RootState): boolean => state.restaurants.showRestaurantDetails;
export const getRestaurantDetails = (state: RootState): IRestaurant => state.restaurants.restaurantDetails;

export const checkShowFoodDetails = (state: RootState): boolean => state.restaurants.showFoodDetails;
export const getFoodDetails = (state: RootState): IFood => state.restaurants.foodDetails;

export const getDisplayMode = (state: RootState): IDisplayMode => state.restaurants.displayMode;

export const checkSuggestAreaSelection = (state: RootState): boolean =>
  state.restaurants.displayMode === 'areas' && !state.areas.expanded.length;

/**
 * Memoized selectors
 * https://react-redux.js.org/api/hooks#using-memoizing-selectors
 */
export const getRestaurantOpeningTimes = createSelector(getRestaurantData, (data) => {
  const openingTimes = data.map((restaurant) => {
    const { title, menuData } = restaurant || {};
    const { visitingHours } = menuData || {};

    return {
      title,
      visitingHours,
    };
  });

  return openingTimes.sort((a, b) => {
    return a.title.localeCompare(b.title, 'fi');
  });
});

export const getFilteredRestaurantsString = createSelector(
  getRestaurantData,
  getFilteredRestaurants,
  (data, filtered) => {
    const filteredData = data
      .filter((restaurant) => filtered.includes(restaurant.id))
      .map((restaurant) => restaurant.title);

    return filteredData.join(', ');
  }
);

export const makeIsRestaurantClosedSelector = () =>
  createSelector(
    getRestaurantData,
    (_: any, id: number) => id,
    (restaurants, restaurantId) => {
      const restaurant = restaurants.find((each) => each.id === restaurantId);

      // Let's assume restaurant is closed if something goes wrong
      if (!restaurant) {
        return true;
      }

      /**
       * If the "business" key of visiting hours is marked as "false" in the API,
       * this restaurant is considered closed.
       */
      const { menuData } = restaurant || {};
      const { visitingHours } = menuData || {};
      const { business } = visitingHours || {};

      if (business === false || typeof business === 'undefined') {
        return true;
      }

      // Let's check if there's a "exceptionUndefinedTime" key set to true, which means the restaurant is closed
      let exceptionUndefinedTime = business.items.filter((item) => item.exceptionUndefinedTime);

      if (exceptionUndefinedTime.length) {
        return true;
      }

      // If all data was found and restaurant had business hours, it's not considered closed.
      return false;
    }
  );
