import { AppThunk } from '../';
import { getCurrentDate } from 'utils/date';

export const SET_SELECTED_DATE: string = '[date] Set Selected Date';
export const UPDATE_TODAY: string = '[date] Update Today';
export const TOGGLE_MODAL: string = '[date] Toggle Modal';

export const updateToday = (setAsSelected: boolean = false): AppThunk => (dispatch, getState) => {
  const newToday = getCurrentDate();

  dispatch({
    type: UPDATE_TODAY,
    payload: newToday,
  });

  if (setAsSelected) {
    dispatch(setSelectedDate(newToday));
  }
};

export const setSelectedDate = (date: string) => ({
  type: SET_SELECTED_DATE,
  payload: date,
});

export const toggleDateModal = () => ({
  type: TOGGLE_MODAL,
});
