import { useSelector } from 'react-redux';
import { createTranslator } from './createTranslator';
import { getActiveLanguage } from 'store/translator/selectors';
import { TranslationFile } from './types';

let translator = createTranslator();

export function useTranslation() {
  const activeLanguage = useSelector(getActiveLanguage);

  const get = <T extends keyof TranslationFile, U extends keyof TranslationFile[T]>(
    namespace: T,
    id: U
  ): TranslationFile[T][U] => translator.setLanguage(activeLanguage).get(namespace, id);

  const allergens = (allergens: string[]): string[] => translator.setLanguage(activeLanguage).allergens(allergens);
  const label = (label: string): string => translator.setLanguage(activeLanguage).label(label);

  return {
    get,
    allergens,
    label,
  };
}
