import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './RestaurantList.module.scss';

import { checkIsDataLoading } from 'store/data/selectors';
import { getDisplayMode } from 'store/restaurants/selectors';

import Spinner from 'components/Spinner';
import RestaurantsListedByDistance from './RestaurantsListedByDistance';
import RestaurantsListedByFiltered from './RestaurantsListedByFiltered';
import RestaurantsListedInsideAreas from './RestaurantsListedInsideAreas';

export type IDisplayMode = 'areas' | 'filtered' | 'distance';

const RestaurantList: React.FunctionComponent = () => {
  const isLoading: boolean = useSelector(checkIsDataLoading);
  const displayMode: IDisplayMode = useSelector(getDisplayMode);

  const content = (function (displayMode: IDisplayMode) {
    if (isLoading) {
      return <Spinner />;
    }

    switch (displayMode) {
      case 'distance': {
        return <RestaurantsListedByDistance />;
      }
      case 'filtered': {
        return <RestaurantsListedByFiltered />;
      }
      default: {
        return <RestaurantsListedInsideAreas />;
      }
    }
  })(displayMode);

  const componentClass = classNames(styles.component, {
    [styles.isLoading]: isLoading,
  });

  return <div className={componentClass}>{content}</div>;
};

export default RestaurantList;
