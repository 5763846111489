import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './Filters.module.scss';

import { getRestaurantData } from 'store/data/selectors';
import { arrangeRestaurantsInsideAreas } from 'utils/restaurants';

import Tab from './Tab';
import RestaurantList from './RestaurantList';

const Filters: React.FunctionComponent = () => {
  const restaurants = useSelector(getRestaurantData);
  const areas = arrangeRestaurantsInsideAreas(restaurants);
  const [activeArea, setActiveArea] = useState(areas.length ? areas[0].id : 0);

  if (!areas.length) {
    return null;
  }

  return (
    <div className={styles.component}>
      <div className={styles.tabs}>
        {areas.map((area) => (
          <Tab
            key={area.id}
            title={area.name}
            isActive={area.id === activeArea}
            clickHandler={() => setActiveArea(area.id)}
          />
        ))}
      </div>
      <div className={styles.lists}>
        {areas
          .filter((area) => area.id === activeArea)
          .map((area) => (
            <RestaurantList key={area.id} area={area.name} restaurants={area.restaurants} />
          ))}
      </div>
    </div>
  );
};

export default Filters;
