import { createStore, applyMiddleware, compose, Action, Reducer } from 'redux';
import { persistStore } from 'redux-persist';
import thunk, { ThunkAction } from 'redux-thunk';
import rootReducer, { RootState } from './rootReducer';

const composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const makeStore = (reducer: Reducer) => createStore(reducer, composeEnhancer(applyMiddleware(thunk)));
export const store = makeStore(rootReducer);
export const persistor = persistStore(store);
