/**
 * Replaces all matching text pieces with the provided object.
 */
export function replaceAll(str: string, mapObj: any): string {
  var re = new RegExp(Object.keys(mapObj).join('|'), 'gi');

  return str.replace(re, function (matched) {
    return mapObj[matched.toLowerCase()];
  });
}

export function isProduction(): boolean {
  return process.env.NODE_ENV === 'production';
}

export function isDevelopment(): boolean {
  return process.env.NODE_ENV === 'development';
}

export function developerWarning(str: string): void {
  if (isDevelopment()) {
    console.warn(str);
  }
}

export function developerError(str: string): void {
  if (isDevelopment()) {
    console.error(str);
  }
}

export function capitalize(str: string): string {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}
