import React, { useEffect } from 'react';
import { getSEOTitle } from 'utils/SEO';

interface Props {
  title: string[];
}

const Metadata: React.FunctionComponent<Props> = ({ title }) => {
  useEffect(() => {
    document.title = getSEOTitle(title);
  }, [title]);

  return null;
};

export default Metadata;
