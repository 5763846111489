import React from 'react';
import { useSelector } from 'react-redux';
import styles from './FoodDetails.module.scss';

import { getFoodDetails } from 'store/restaurants/selectors';
import { IFood } from './Food';
import FoodCategory from './FoodCategory';
import FoodName from './FoodName';
import FoodDiets from './FoodDiets';
import { useTranslation } from 'features/translator';

/**
 * @todo Nutrition values are weird coming from the API (our WordPress).
 * Need to go through the string replacements it does.
 */
const FoodDetails: React.FunctionComponent = () => {
  const translation = useTranslation();
  const food: IFood = useSelector(getFoodDetails);
  const { name, ingredients, price, meta, nutrition } = food || {};
  const diets = meta[0] || [];
  const allergens = meta[1] || [];
  const labels = meta[2] || [];

  const hasDiets = !!diets.length;
  const hasAllergens = !!allergens.length;
  const hasLabels = !!labels.length;
  const hasIngredients = !!ingredients.length;
  const hasNutrition = !!nutrition.length;
  const hasPrices = !!price.value;

  return (
    <div className={styles.component}>
      <div className={styles.name}>
        <FoodName text={name} size="large"></FoodName>
      </div>
      <FoodCategory data={food} size="large"></FoodCategory>

      {hasDiets && (
        <div className={styles.diets}>
          <FoodDiets diets={diets} size="large"></FoodDiets>
        </div>
      )}

      {hasLabels && <div className={styles.label}>{translation.label(labels[0])}</div>}

      {hasAllergens && (
        <div className={styles.allergens}>
          <h5 className={styles.subtitle}>{translation.get('foods', 'allergens')}</h5>
          <p className={styles.allergenList}>{translation.allergens(allergens).join(', ')}</p>
        </div>
      )}

      {hasIngredients && (
        <div className={styles.ingredients}>
          <h5 className={styles.subtitle}>{translation.get('foods', 'ingredients')}</h5>
          <p>{ingredients}</p>
        </div>
      )}

      {hasNutrition && (
        <div className={styles.nutrition}>
          <h5 className={styles.subtitle}>{translation.get('foods', 'nutrition')}: </h5>
          <p>{nutrition}</p>
        </div>
      )}

      {hasPrices && (
        <div className={styles.prices}>
          <h5 className={styles.subtitle}>{translation.get('pricing', 'title')}</h5>
          <ul className={styles.priceList}>
            <li className={styles.price}>
              <span>{translation.get('pricing', 'student')}</span> <span>{price.value.student}€</span>
            </li>
            <li className={styles.price}>
              <span>{translation.get('pricing', 'graduate')}</span> <span>{price.value.graduate}€</span>
            </li>
            <li className={styles.price}>
              <span>{translation.get('pricing', 'contract')}</span> <span>{price.value.contract}€</span>
            </li>
            <li className={styles.price}>
              <span>{translation.get('pricing', 'normal')}</span> <span>{price.value.normal}€</span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default FoodDetails;
