/**
 * This file is used to migrate the user's localForage database (IndexedDB)
 * between different versions of the state tree.
 *
 * If you make a change to a tracked state object, you need to upgrade
 * the version number in the persistConfig and then write the migration
 * logic here.
 *
 * Read more at https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md
 */
export default {
  /**
   * This is the launch state of the store.
   */
  0: (state: any) => {
    return {
      ...state,
    };
  },
  1: (state: any) => {
    return {
      ...state,
      lastUpdate: undefined,
      restaurants: [],
      dates: [],
    };
  },
};
