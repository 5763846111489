import axios from 'axios';
import { differenceInMinutes } from 'date-fns';

import { IRestaurant } from 'pages/Lunch/Restaurant';
import { AppThunk } from '../';
import { getApiUrl, normalizeRestaurantData } from 'utils/api';
import { checkHasData, getLastUpdate } from './selectors';
import { getActiveLanguage } from '../translator/selectors';

export const LOAD_DATA_REQUEST: string = '[data] Load Data Request';
export const LOAD_DATA_COMPLETE: string = '[data] Load Data Complete';
export const LOAD_DATA_FAILED: string = '[data] Load Data Failed';
export const LOAD_DATA_SKIPPED: string = '[data] Load Data Skipped';
export const UPDATE_RESTAURANTS: string = '[data] Update Restaurants';
export const UPDATE_DATES: string = '[data] Update Dates';

const CACHE_TIME_IN_MINUTES = 5;

export const loadData =
  (allowSkip: boolean = true): AppThunk =>
  (dispatch, getState) => {
    dispatch(loadDataRequest());

    const currentDate = new Date();
    const hasData = checkHasData(getState());
    const lastUpdate = getLastUpdate(getState());
    const activeLanguage = getActiveLanguage(getState());

    if (
      allowSkip &&
      hasData &&
      lastUpdate &&
      differenceInMinutes(currentDate, new Date(lastUpdate)) < CACHE_TIME_IN_MINUTES
    ) {
      return dispatch(loadDataSkipped());
    }

    axios
      .get(getApiUrl('restaurants', activeLanguage))
      .then((response) => {
        const { data } = response;
        const normalizedData = normalizeRestaurantData(data);

        return dispatch(loadDataComplete(normalizedData));
      })
      .catch(() => {
        return dispatch(loadDataFailed());
      });
  };

export const loadDataRequest = () => ({
  type: LOAD_DATA_REQUEST,
});

export const loadDataSkipped = () => ({
  type: LOAD_DATA_SKIPPED,
});

export const loadDataFailed = () => ({
  type: LOAD_DATA_FAILED,
});

export const loadDataComplete =
  (restaurants: IRestaurant[]): AppThunk =>
  (dispatch) => {
    dispatch(updateRestaurants(restaurants));
    dispatch(updateDates(restaurants));

    return dispatch({
      type: LOAD_DATA_COMPLETE,
    });
  };

export const updateRestaurants = (restaurants: IRestaurant[]) => ({
  type: UPDATE_RESTAURANTS,
  payload: restaurants,
});

export const updateDates =
  (restaurants: IRestaurant[]): AppThunk =>
  (dispatch) => {
    const { menuData } = restaurants[0] || {};

    if (!menuData) {
      return;
    }

    const { menus } = menuData || [];
    const dates = menus.map((menu) => menu.date);

    return dispatch({
      type: UPDATE_DATES,
      payload: dates,
    });
  };
