import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useHistory, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import styles from './ConsentModal.module.scss';
import { setAnalyticsPermission, consentAsked } from 'store/system/actions';
import { checkIsConsentAsked } from 'store/system/selectors';
import { useTranslation } from 'features/translator';
import StandardContent from 'components/StandardContent';

const slideUp = {
  initial: { opacity: 0, y: '100%' },
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: '100%' },
};

const ConsentModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isConsentAsked = useSelector(checkIsConsentAsked);
  const translation = useTranslation();

  const handleSettingsClick = () => {
    dispatch(consentAsked());

    history.push('/info/yksityisyys', {
      oldRoute: location.pathname,
    });
  };

  const handleAllowClick = () => {
    dispatch(consentAsked());
    dispatch(setAnalyticsPermission(true));
  };

  return (
    <AnimatePresence>
      {!isConsentAsked && (
        <div className={styles.component}>
          <motion.div
            className={styles.background}
            initial={'initial'}
            animate={'open'}
            exit={'closed'}
            variants={{
              initial: { opacity: 0 },
              open: { opacity: 1 },
              closed: { opacity: 0 },
            }}
            transition={{ duration: 0.15 }}></motion.div>
          <motion.div
            className={styles.content}
            initial={'initial'}
            animate={'open'}
            exit={'closed'}
            variants={slideUp}
            transition={{ type: 'spring', mass: 0.5, stiffness: 300, damping: 25 }}>
            <StandardContent>
              <p>{translation.get('cookies', 'title')}</p>
              <div className={styles.buttons}>
                <button className={styles.settingsButton} onClick={handleSettingsClick}>
                  {translation.get('cookies', 'settings')}
                </button>
                <button className={styles.allowButton} onClick={handleAllowClick}>
                  {translation.get('cookies', 'allow')}
                </button>
              </div>
            </StandardContent>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ConsentModal;
