import React from 'react';
import { useLocation } from 'react-router-dom';
import { MotionLink, LocationWithOldRoute } from 'features/motion-router';

import Button from 'components/Button';
import { useTranslation } from 'features/translator';

const BackButton = () => {
  const location: LocationWithOldRoute = useLocation();
  const translation = useTranslation();

  // set back route based on location history or by getting the parent route
  const route = location.pathname;
  const { oldRoute } = location.state || {};
  let backRoute;

  if (oldRoute) {
    backRoute = oldRoute;
  } else {
    backRoute = route.substring(0, route.lastIndexOf('/'));
  }

  return (
    <MotionLink to={backRoute}>
      <Button
        text={translation.get('general', 'back')}
        theme={'green'}
        icon={'chevron-left'}
        iconPlacement={'before'}></Button>
    </MotionLink>
  );
};

export default BackButton;
