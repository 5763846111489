import React from 'react';
import { motion } from 'framer-motion';
import styles from './NavigationIcon.module.scss';

import { ReactComponent as LunchIcon } from './assets/lunch-icon.svg';
import { ReactComponent as LunchActiveIcon } from './assets/lunch-active-icon.svg';
import { ReactComponent as InfoIcon } from './assets/info-icon.svg';
import { ReactComponent as InfoActiveIcon } from './assets/info-active-icon.svg';
import { ReactComponent as SettingsIcon } from './assets/settings-icon.svg';
import { ReactComponent as SettingsActiveIcon } from './assets/settings-active-icon.svg';
import { useTranslation } from 'features/translator';

interface INavigationIcon {
  label: string;
  active: boolean;
}

const NavigationIcon: React.FunctionComponent<INavigationIcon> = ({ label, active }) => {
  const translation = useTranslation();

  const icon = (function (label: string, active: boolean) {
    switch (label) {
      case translation.get('lunchPage', 'navigationTitle'): {
        return active ? <LunchActiveIcon /> : <LunchIcon />;
      }
      case translation.get('infoPage', 'navigationTitle'): {
        return active ? <InfoActiveIcon /> : <InfoIcon />;
      }
      case translation.get('settingsPage', 'navigationTitle'): {
        return active ? <SettingsActiveIcon /> : <SettingsIcon />;
      }
      default: {
        return null;
      }
    }
  })(label, active);

  if (!icon) {
    return null;
  }

  return (
    <motion.div className={styles.component} whileTap={{ scale: 0.5 }} whileHover={{ scale: 1.1 }}>
      {icon}
    </motion.div>
  );
};

export default NavigationIcon;
