import React from 'react';

const FinnishContent = () => {
  return (
    <>
      <p>
        Hävikkiruoka, luomu, Reilu kauppa, vegaaniruoka - meidät UniCafessa tunnetaan fiksujen ja kestävien valintojen
        mahdollistajana. Valmistamme lompakollesi ja ympäristölle ystävällistä ruokaa, jota voit nauttia helposti ja
        hyvillä mielin. Olemme opiskelijaruokailun tulevaisuuden suunnannäyttäjänä ja ylpeitä siitä.
      </p>

      <p>
        Menestysreseptimme on kokata ruokaa, joka tekee hyvää. Filosofiamme mukaan jokaisen annoksen on oltava niin
        vastuullista, kotimaista ja terveellistä kuin hyvän maun rajoissa on vain mahdollista. Eettisyys, maku ja hinta
        eivät ole joko-tai-juttuja kun tietää mitä tekee. Oli kyse sitten uusista raaka-aineista, kuten nyhtökaurasta
        tai sirkoista, etsimme uteliaasti uutuuksia, joilla ilahduttaa asiakkaitamme. Listaltamme löytyvät niin modernit
        klassikot kuin yllättävät maailman maut.
      </p>

      <p>
        Ovemme ovat auki kaikille kaupungissa. Kokoa siis parhaat tyypit pöydän ääreen ja ota tovi itsellesi keskellä
        kiireistä päivää. Sillä eihän maailmaa tyhjällä vatsalla voi pelastaa.
      </p>

      <p>
        <a href="https://unicafe.fi/ruoka/" target="_blank" rel="noopener noreferrer">
          Lue lisää verkkosivuiltamme
        </a>
      </p>
    </>
  );
};

export default FinnishContent;
