import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './Restaurant.module.scss';

import { RootState } from 'store/rootReducer';
import { Times } from 'components/OpeningTimes';
import { toggleRestaurantCollapse, openRestaurantDetails } from 'store/restaurants/actions';
import { checkIsRestaurantCollapsed, makeIsRestaurantClosedSelector } from 'store/restaurants/selectors';
import { useMenus } from './use-menus';

import Menu, { IMenu } from './Menu';
import { ReactComponent as Kebab } from './assets/kebab.svg';
import { useTranslation } from 'features/translator';

export interface IVisitingHours {
  business: Times | false;
  breakfast: Times | false;
  bistro: Times | false;
  lounas: Times | false;
}

export interface IMenuData {
  name: string;
  id: number;
  areacode: number;
  phone: string;
  email: string;
  feedback_address: string;
  description: string;
  visitingHours: IVisitingHours;
  menus: IMenu[];
}

export interface IRestaurant {
  id: number;
  title: string;
  slug: string;
  permalink: string;
  location: {
    id: number;
    name: string;
  }[];
  address: string;
  menuData?: IMenuData;
}

const Restaurant: React.FunctionComponent<IRestaurant> = (props) => {
  const { menuData } = props;

  if (!menuData) {
    return null;
  }

  return <RestaurantWithMenuData {...props} />;
};

const RestaurantWithMenuData: React.FunctionComponent<IRestaurant> = (props) => {
  const { title, id, menuData } = props;
  const dispatch = useDispatch();
  const collapsed = useSelector((state: RootState) => checkIsRestaurantCollapsed(state)(id));
  const checkIsRestaurantClosed = useMemo(makeIsRestaurantClosedSelector, []);
  const isClosed = useSelector((state: RootState) => checkIsRestaurantClosed(state, id));
  const { menus } = useMenus(menuData);
  const translation = useTranslation();

  const handleToggle = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(toggleRestaurantCollapse(id));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    event.stopPropagation();

    if (event.key === 'Enter') {
      dispatch(toggleRestaurantCollapse(id));
    }
  };

  const hasMenus = !!menus.length;
  const componentClass = classNames(styles.component, {
    [styles.hasError]: !hasMenus,
    [styles.isClosed]: isClosed,
  });

  /**
   * If restaurant is closed, let's show a stripped down,
   * non-interactive version of it.
   */
  if (isClosed) {
    return (
      <div className={componentClass}>
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          <span className={styles.headerMessage}>{translation.get('restaurants', 'closed')}</span>
        </div>
      </div>
    );
  }

  return (
    <div className={componentClass} aria-expanded={!collapsed}>
      <div className={styles.header} role="button" tabIndex={0} onClick={handleToggle} onKeyDown={handleKeyDown}>
        <h2 className={styles.title}>{title}</h2>
      </div>

      <DetailsTrigger restaurant={props} />

      <AnimatePresence initial={false}>
        {!collapsed && (
          <Wrapper>
            {hasMenus ? (
              <div className={styles.menus}>
                {menus.map((menu: IMenu) => (
                  <Menu key={`${title} ${menu.date}`} {...menu}></Menu>
                ))}
              </div>
            ) : (
              <div className={styles.errorMessage}>
                Ravintolalle ei löytynyt lounaslistaa valitulle päivälle. Vaihda päivää tai yritä myöhemmin uudelleen.
              </div>
            )}
          </Wrapper>
        )}
      </AnimatePresence>
    </div>
  );
};

const DetailsTrigger: React.FunctionComponent<{ restaurant: IRestaurant }> = ({ restaurant }) => {
  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(openRestaurantDetails(restaurant));
  };

  return (
    <button onClick={handleClick} className={styles.detailsTrigger}>
      <Kebab />
    </button>
  );
};

const Wrapper: React.FunctionComponent = ({ children }) => {
  return (
    <motion.div
      initial={'collapsed'}
      animate={'expanded'}
      exit={'collapsed'}
      variants={{
        expanded: { height: 'auto', transition: { type: 'spring', stiffness: 200, mass: 0.25, damping: 20 } },
        collapsed: { height: 0, transition: { type: 'spring', stiffness: 200, mass: 0.1, damping: 10 } },
      }}
      className={styles.wrapper}>
      {children}
    </motion.div>
  );
};

export default Restaurant;
