import React from 'react';
import styles from './Spinner.module.scss';

interface ISpinner {
  scheme?: string;
  size?: string;
}

const Spinner: React.FunctionComponent<ISpinner> = ({ scheme = 'dark', size = 'large' }) => {
  return <div className={`${styles.component} ${styles[scheme]} ${styles[size]}`} />;
};

export default Spinner;
