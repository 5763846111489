import React from 'react';
import styles from './Announcement.module.scss';

interface IAnnouncement {
  name: string;
}

const Announcement: React.FunctionComponent<IAnnouncement> = ({ name }) => {
  let str = `${name}`;
  const lastChar = str.slice(-1);

  if (lastChar !== '.' && lastChar !== '?' && lastChar !== '!') {
    str += '.';
  }

  str += ' ';

  return <p className={styles.component}>{str}</p>;
};

export default Announcement;
