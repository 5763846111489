import React from 'react';
import styles from './ButtonIcon.module.scss';

import { ReactComponent as ChevronRight } from './assets/chevron-right.svg';
import { ReactComponent as ChevronLeft } from './assets/chevron-left.svg';

export type TIcon = 'chevron-right' | 'chevron-left' | React.FunctionComponent | null;

interface IButtonIcon {
  icon?: TIcon;
  offsetX?: number;
  offsetY?: number;
}

const ButtonIcon: React.FunctionComponent<IButtonIcon> = ({ icon, offsetX = 0, offsetY = 0 }) => {
  if (!icon) {
    return null;
  }

  let iconComponent = null;

  if (icon === 'chevron-right') {
    iconComponent = <ChevronRight />;
  } else if (icon === 'chevron-left') {
    iconComponent = <ChevronLeft />;
  } else {
    iconComponent = React.createElement(icon);
  }

  if (!iconComponent) {
    return null;
  }

  return (
    <span className={styles.component} style={{ transform: `translate(${offsetX}px, ${offsetY}px)` }}>
      {iconComponent}
    </span>
  );
};

export default ButtonIcon;
