import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import classNames from 'classnames';
import styles from './Area.module.scss';

import { RootState } from 'store/rootReducer';
import Restaurant, { IRestaurant } from './Restaurant';
import { checkIsAreaExpanded } from 'store/areas/selectors';
import { toggleAreaExpanded } from 'store/areas/actions';
import Container from 'components/Container';
import { useTranslation } from 'features/translator';

export interface IArea {
  id: number;
  name: string;
  restaurants: IRestaurant[];
}

const slideWrapper = {
  expanded: { height: 'auto', y: 0, opacity: 1, scale: 1 },
  collapsed: { height: 0, y: -50, opacity: 0.8, scale: 0.9 },
};

const hideHeader = {
  expanded: { backgroundColor: 'rgba(255,255,255,0)', boxShadow: '0 10px 18px -10px rgba(0,0,9, 0)' },
  collapsed: { backgroundColor: 'rgba(255,255,255,1)', boxShadow: '0 10px 18px -10px rgba(0,0,9, 0.06)' },
};

const Area: React.FunctionComponent<IArea> = ({ id, name, restaurants }) => {
  const dispatch = useDispatch();
  const expanded = useSelector((state: RootState) => checkIsAreaExpanded(state)(id));
  const componentClass = classNames(styles.component, { [styles.isExpanded]: expanded });
  const translation = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();

    dispatch(toggleAreaExpanded(id));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (event.key === 'Enter') {
      dispatch(toggleAreaExpanded(id));
    }
  };

  const displayName = () => {
    switch (name) {
      case 'Keskusta': {
        return translation.get('areas', 'keskusta');
      }
      case 'Meilahti': {
        return translation.get('areas', 'meilahti');
      }
      case 'Viikki': {
        return translation.get('areas', 'viikki');
      }
      case 'Kumpula': {
        return translation.get('areas', 'kumpula');
      }
      default: {
        return name;
      }
    }
  };

  return (
    <div className={componentClass} aria-expanded={expanded}>
      <Container>
        <motion.div
          initial={false}
          animate={expanded ? 'expanded' : 'collapsed'}
          variants={hideHeader}
          transition={{ type: 'spring', mass: 0.1, stiffness: 1000 }}
          className={styles.header}
          role="button"
          tabIndex={0}
          onClick={handleClick}
          onKeyDown={handleKeyDown}>
          <h2 className={styles.title}>{displayName()}</h2>
        </motion.div>
      </Container>

      <AnimatePresence initial={false}>
        {expanded && (
          <motion.div
            key={`area-wrapper-${id}`}
            initial={'collapsed'}
            animate={'expanded'}
            exit={'collapsed'}
            variants={slideWrapper}
            transition={{ type: 'spring', mass: 0.5, stiffness: 500, damping: 100 }}
            className={styles.wrapper}>
            <Container>
              {restaurants.map((restaurant) => (
                <div className={styles.restaurant} key={restaurant.id}>
                  <Restaurant {...restaurant}></Restaurant>
                </div>
              ))}
            </Container>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Area;
