import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.scss';

import * as serviceWorker from 'serviceWorker';
import { store, persistor } from 'store';
import App from 'App';
import vhUnits from 'utils/vhUnits';
import a11y from 'utils/a11y';
import { isProduction } from 'utils/helpers';
import Spinner from 'components/Spinner';

Sentry.init({
  dsn: 'https://57ace3df5227460db7f80944f8aba5d0@o287920.ingest.sentry.io/5519395',
  integrations: [new Integrations.BrowserTracing()],
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Spinner />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root') as HTMLElement
);

window.addEventListener('DOMContentLoaded', () => {
  a11y();
  vhUnits();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (isProduction()) {
  serviceWorker.register({
    onUpdate: (registration) => {
      // Refresh service worker automatically
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
      
      window.location.reload();
    },
  });
} else {
  serviceWorker.unregister();
}
