import React from 'react';

import FAQItem from './FAQItem';

const FinnishFAQ: React.FunctionComponent = () => {
  return (
    <>
      <FAQItem
        question="Kuka voi syödä unicafessa?"
        answer="UniCafen ovet ovat auki kaikille - toivotamme tervetulleeksi jokaisen, jolle maistuu hyvä ruoka!"
      />
      <FAQItem
        question="Mitä lounas maksaa?"
        answer="Katso kaikki lounashintamme <a href='https://menu.unicafe.fi/info/hinnasto'><u>täältä</u></a>."
      />
      <FAQItem
        question="Mitä lounaaseen sisältyy?"
        answer="Lounas koostuu lämpimästä pääruoasta ja lisukkeesta (esimerkiksi perunat tai riisi) tai salaattiannoksesta. Lisäksi lounaaseen kuuluu leipä ja levite sekä juoma. Lisämaksusta voit nauttia myös jälkiruoasta tai lisätä ateriaan lounaskahvin."
      />
      <FAQItem
        question="Miten saan opiskelija-alennuksen?"
        answer="Opiskelija-alennuksen saat esittämällä kassalla henkilökunnalle ateriatukeen oikeuttavan opiskelijakortin. Lista korteista löytyy Kelan sivuilta. Jos olet juuri aloittanut opinnot eikä sinulla ei vielä ole opiskelijakorttia, voit väliaikaisesti saada alennuksen esittämällä läsnäolotodistuksen."
      />
      <FAQItem
        question="Saako henkilökunta alennuksia?"
        answer="Henkilökunta on oikeutettu alennukseen. Alennuksen saat esittämällä kassalla henkilökunta-alennukseen oikeuttavan kortin."
      />
      <FAQItem
        question="Haluan tuoda ryhmän koululaisia ruokailemaan UniCafeseen. Miten toimin? Ovatko koululaiset oikeutettuja alennuksiin?"
        answer="Koululaiset eivät valitettavasti ole oikeutettuja ateriatukeen. Ryhmät ovat kuitenkin meille tervetulleita. Suosittelemme olemaan yhteydessä suoraan ravintolaan, jossa toivot ryhmäsi aterioivan, ja sopimaan järjestelyistä henkilökunnan kanssa."
      />
      <FAQItem
        question="Onko teillä ryhmäalennuksia?"
        answer="Järjestämme mahdollisuuksien mukaan ryhmille ateriointimahdollisuuksia esimerkiksi koulutuksien tai harjoitteluiden sekä muiden tilaisuuksien yhteydessä. Suosittelemme olemaan yhteydessä suoraan ravintolaan, jossa toivot ryhmäsi aterioivan, ja sopimaan järjestelyistä henkilökunnan kanssa."
      />
      <FAQItem
        question="Voivatko opiskelijat syödä opettajien ruokalassa?"
        answer="Pääsääntöisesti opettajien ravintola on henkilökunnan käyttöön suunnattu tila."
      />
      <FAQItem
        question="Miksi teillä ei ole tarjolla jotakin tiettyä, suosittua ruokaa joka päivä?"
        answer="Vaihtelu virkistää! Tiedämme, että osa lounasvaihtoehdoistamme on rakastettuja klassikoita - hyvää kannattaa odottaa."
      />
      <FAQItem
        question="Kenelle voin lähettää palautetta?"
        answer="Voit lähettää palautetta suoraan ravintolalle sähköpostilla tai palautejärjestelmämme kautta."
      />
      <FAQItem
        question="Miksi ruoat joskus loppuvat kesken?"
        answer="Valmistamme ruokaa vain niin paljon, kuin arvioimme sitä tarvittavan, jottei ruokahävikkiä syntyisi ihan mielettömän paljon. Siksi jokin suosittu ruoka voi joskus loppua kesken päivän aikana."
      />
      {/*<FAQItem
        question="Miten Compensate toimii?"
        answer="Kompensoidaksesi ateriasi hiilipäästöt ilmoita vain kassalla maksun yhteydessä haluavasi tehdä näin. Siirrämme maksun lyhentämättömänä Compensatelle, joka puolestaan ohjaa maksun edelleen lyhentämättömänä metsityshankkeisiin."
  />*/}
      <FAQItem
        question="Mikä on Leftover-lounas?"
        answer="Leftover-lounaat ovat päivän ylijääneistä aterioista koostettuja, alennettuun hintaan myytäviä lounaita. Voit ostaa Leftover-lounaan ravintolan lounasmyynnin päätyttyä joko UniCafen laatikossa tai omassa eväslaatikossasi."
      />
      <FAQItem
        question="Voinko maksaa kahvilatuotteita lounaskassalla?"
        answer="Kyllä voit - paitsi niissä tiloissa, missä kahvila ja lounasravintola sijaitsevat eri tiloissa."
      />
      <FAQItem
        question="Ovatko UniCafen pakkausmateriaalit, kuten take away -kupit, biohajoavia?"
        answer="Tällä hetkellä take away -tuotteiden pakkausmateriaaleista vain osa on biohajoavia. Tavoitteemme on, että 2020 aikana kaikki UniCafen take away -tuotteiden pakkausmateriaalit ovat biohajoavia."
      />
    </>
  );
};

export default FinnishFAQ;
