import { getCurrentDate } from 'utils/date';
import { SET_SELECTED_DATE, UPDATE_TODAY, TOGGLE_MODAL } from './actions';

interface IState {
  today: string;
  selected: string;
  showModal: boolean;
}

const currentDate = getCurrentDate();

const initialState: IState = {
  today: currentDate,
  selected: currentDate,
  showModal: false,
};

export default function (state: IState = initialState, action: any) {
  switch (action.type) {
    case SET_SELECTED_DATE: {
      return {
        ...state,
        selected: action.payload,
      };
    }
    case UPDATE_TODAY: {
      return {
        ...state,
        today: action.payload,
      };
    }
    case TOGGLE_MODAL: {
      return {
        ...state,
        showModal: !state.showModal,
      };
    }
    default:
      return state;
  }
}
