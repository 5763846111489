import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import styles from './DataReloader.module.scss';

import { ReactComponent as Reload } from './assets/reload.svg';

import { updateToday } from 'store/date/actions';
import { loadData } from 'store/data/actions';

const DataReloader = () => {
  const dispatch = useDispatch();
  const [rotation, setRotation] = useState(0);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setRotation(rotation - 360);
    dispatch(loadData(false));
    dispatch(updateToday(true));
  };

  return (
    <motion.button
      animate={{ rotate: rotation }}
      transition={{ type: 'spring', mass: 0.5, damping: 100 }}
      className={styles.component}
      onClick={handleClick}>
      <Reload></Reload>
    </motion.button>
  );
};

export default DataReloader;
