import React from 'react';
import styles from './SettingsPage.module.scss';
import { MotionLink } from 'features/motion-router';

import Page from 'components/Page';
import ScrollWrapper from 'components/ScrollWrapper';
import StandardContent from 'components/StandardContent';
import Container from 'components/Container';
import MenuButton from 'components/MenuButton';
import ButtonGroup from 'components/ButtonGroup';
import { useTranslation } from 'features/translator';

import { ReactComponent as LanguageIcon } from './assets/language.svg';
import { ReactComponent as DietsIcon } from './assets/diets.svg';
import { ReactComponent as RestaurantsIcon } from './assets/restaurants.svg';

const SettingsPage: React.FunctionComponent = () => {
  const translation = useTranslation();

  return (
    <Page name="Asetukset" title={[translation.get('settingsPage', 'title')]}>
      <div className={styles.component}>
        <ScrollWrapper>
          <div className={styles.wrapper}>
            <Container>
              <div className={styles.content}>
                <StandardContent>
                  <h1>{translation.get('settingsPage', 'title')}</h1>
                  <p>{translation.get('settingsPage', 'description')}</p>
                </StandardContent>
              </div>

              <div className={styles.buttons}>
                <ButtonGroup>
                  <MotionLink to="/asetukset/kieli">
                    <MenuButton
                      icon={LanguageIcon}
                      text={translation.get('languagePage', 'navigationTitle')}
                      theme="green"
                    />
                  </MotionLink>
                </ButtonGroup>
                <ButtonGroup>
                  <MotionLink to="/asetukset/ruokavalio">
                    <MenuButton icon={DietsIcon} text={translation.get('foodsPage', 'navigationTitle')} />
                  </MotionLink>
                  <MotionLink to="/asetukset/ravintolat">
                    <MenuButton icon={RestaurantsIcon} text={translation.get('restaurantsPage', 'navigationTitle')} />
                  </MotionLink>
                </ButtonGroup>
              </div>
            </Container>
          </div>
        </ScrollWrapper>
      </div>
    </Page>
  );
};

export default SettingsPage;
