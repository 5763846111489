import React from 'react';
import styles from './FoodsPage.module.scss';

import Page from 'components/Page';
import Container from 'components/Container';
import ScrollWrapper from 'components/ScrollWrapper';
import StandardContent from 'components/StandardContent';
import BackButton from 'components/BackButton';
import DietFilters from './DietFilters';
import { useTranslation } from 'features/translator';

const FoodsPage: React.FunctionComponent = () => {
  const translation = useTranslation();

  return (
    <Page name="Ruokavalio" title={[translation.get('settingsPage', 'title'), translation.get('foodsPage', 'title')]}>
      <div className={styles.component}>
        <ScrollWrapper>
          <Container>
            <div className={styles.back}>
              <BackButton></BackButton>
            </div>
            <div className={styles.header}>
              <StandardContent>
                <h1>{translation.get('foodsPage', 'title')}</h1>
                <p>{translation.get('foodsPage', 'description')}</p>
              </StandardContent>
            </div>
            <div className={styles.filters}>
              <DietFilters></DietFilters>
            </div>
            <div className={styles.allergensHeader}>
              <StandardContent>
                <h3>{translation.get('foodsPage', 'allergenTitle')}</h3>
                <p>{translation.get('foodsPage', 'allergenDescription')}</p>
              </StandardContent>
            </div>
          </Container>
        </ScrollWrapper>
      </div>
    </Page>
  );
};

export default FoodsPage;
