import { useRoutes } from './use-routes';
import { UnsureRoute, Route } from './types';
import { slideFromRight, slideFromLeft, zoomOut, zoomIn, reveal } from './animations';
import { useRouteRelationshipFinder } from './use-route-relationship-finder';

const routeAnimations = {
  next: slideFromRight(),
  previous: slideFromLeft(),
  deeper: zoomOut(),
  shallower: zoomIn(),
  same: reveal(),
  unknown: reveal(),
};

export function useAnimation() {
  const { currentRoute, oldRoute } = useRoutes();
  const routeRelationshipFinder = useRouteRelationshipFinder();

  const pickAnimation = (routeA: UnsureRoute, routeB: UnsureRoute) => {
    const relationship = routeRelationshipFinder.setRoutes(routeA, routeB).find();
    return routeAnimations[relationship];
  };

  return {
    getInitial() {
      return pickAnimation(currentRoute, oldRoute)['initial'];
    },
    getEnter() {
      return pickAnimation(currentRoute, oldRoute)['enter'];
    },
    getExit(newRoute: Route) {
      return pickAnimation(newRoute, currentRoute)['exit'];
    },
  };
}
