import { useSelector, useDispatch } from 'react-redux';
import { setActiveLanguage as setStoreActiveLanguage } from 'store/translator/actions';
import { getActiveLanguage } from 'store/translator/selectors';
import { Language } from './types';

export function useLanguage() {
  const activeLanguage = useSelector(getActiveLanguage);
  const dispatch = useDispatch();

  function setActiveLanguage(language: Language) {
    dispatch(setStoreActiveLanguage(language));
  }

  return [activeLanguage, setActiveLanguage] as const;
}
