import React from 'react';
import styles from './Page.module.scss';

import Metadata from './Metadata';
import PageView from './PageView';

interface Props {
  name: string;
  title: string[];
}

const Page: React.FunctionComponent<Props> = ({ children, name, title }) => {
  return (
    <div className={styles.component}>
      <Metadata title={title} />
      <PageView name={name} />
      {children}
    </div>
  );
};

export default Page;
