import fi from './translations/fi.json';

import sv from './translations/sv.json';
import svAllergens from './translations/sv-allergens.json';
import svLabels from './translations/sv-labels.json';

import en from './translations/en.json';
import enAllergens from './translations/en-allergens.json';
import enLabels from './translations/en-labels.json';

export const defaultLanguage = 'fi';

export const languages = {
  fi: {
    name: 'Suomi',
    translation: fi,
    allergens: null,
    labels: null,
  },
  sv: {
    name: 'Svenska',
    translation: sv,
    allergens: svAllergens,
    labels: svLabels,
  },
  en: {
    name: 'English',
    translation: en,
    allergens: enAllergens,
    labels: enLabels,
  },
};
