import localForage from 'localforage';
import { createMigrate, REHYDRATE } from 'redux-persist';
import migrations from './migrations';
import { IRestaurant } from 'pages/Lunch/Restaurant';
import { IFood } from 'pages/Lunch/Food';
import { IDisplayMode } from 'pages/Lunch/RestaurantList';
import {
  TOGGLE_COLLAPSE,
  TOGGLE_FILTER,
  ADD_FILTERS,
  REMOVE_FILTERS,
  CLEAR_FILTERS,
  OPEN_DETAILS,
  CLOSE_DETAILS,
  OPEN_FOOD_DETAILS,
  CLOSE_FOOD_DETAILS,
  SET_DISPLAY_MODE,
} from './actions';

/**
 * Redux Persist configuration object. Add keys
 * you want to remain in storage into the whitelist array.
 *
 * The version number is used in database migrations.
 */
export const restaurantsPersistConfig = {
  key: 'restaurants',
  version: 0,
  storage: localForage,
  whitelist: ['filtered', 'collapsed'],
  migrate: createMigrate(migrations, { debug: process.env.NODE_ENV === 'development' }),
};

/**
 * Types
 */
interface IState {
  filtered: number[];
  collapsed: number[];
  showRestaurantDetails: boolean;
  restaurantDetails: IRestaurant;
  showFoodDetails: boolean;
  foodDetails: IFood;
  displayMode: IDisplayMode;
}

const initialState: IState = {
  filtered: [],
  collapsed: [],
  showRestaurantDetails: false,
  restaurantDetails: {} as IRestaurant,
  showFoodDetails: false,
  foodDetails: {} as IFood,
  displayMode: 'areas',
};

/**
 * Reducer
 */
export default function (state: IState = initialState, action: any) {
  switch (action.type) {
    case REHYDRATE: {
      if (action.key === 'restaurants') {
        const { filtered } = action.payload || {};

        return {
          ...state,
          displayMode: filtered && filtered.length ? 'filtered' : 'areas',
        };
      }

      return state;
    }
    case TOGGLE_COLLAPSE: {
      return {
        ...state,
        collapsed: state.collapsed.includes(action.payload)
          ? state.collapsed.filter((id) => id !== action.payload)
          : state.collapsed.concat(action.payload),
      };
    }
    case TOGGLE_FILTER: {
      const filtered = state.filtered.includes(action.payload)
        ? state.filtered.filter((id) => id !== action.payload)
        : state.filtered.concat(action.payload);

      return {
        ...state,
        filtered: filtered,
        displayMode: filtered && filtered.length ? 'filtered' : 'areas',
      };
    }
    case ADD_FILTERS: {
      const merged = state.filtered.concat(action.payload);
      const filtered = merged.filter((el, i, array) => array.indexOf(el) === i);

      return {
        ...state,
        filtered: filtered,
        displayMode: filtered && filtered.length ? 'filtered' : 'areas',
      };
    }
    case REMOVE_FILTERS: {
      const filtered = state.filtered.filter((id) => !action.payload.includes(id));

      return {
        ...state,
        filtered: filtered,
        displayMode: filtered && filtered.length ? 'filtered' : 'areas',
      };
    }
    case CLEAR_FILTERS: {
      return {
        ...state,
        filtered: [],
        displayMode: 'areas',
      };
    }
    case OPEN_DETAILS: {
      return {
        ...state,
        showRestaurantDetails: true,
        restaurantDetails: action.payload,
      };
    }
    case CLOSE_DETAILS: {
      return {
        ...state,
        showRestaurantDetails: false,
      };
    }
    case OPEN_FOOD_DETAILS: {
      return {
        ...state,
        showFoodDetails: true,
        foodDetails: action.payload,
      };
    }
    case CLOSE_FOOD_DETAILS: {
      return {
        ...state,
        showFoodDetails: false,
      };
    }
    case SET_DISPLAY_MODE: {
      return {
        ...state,
        displayMode: action.payload,
      };
    }
    default:
      return state;
  }
}
