import React from 'react';
import styles from './LunchABCPage.module.scss';

import Page from 'components/Page';
import ScrollWrapper from 'components/ScrollWrapper';
import Container from 'components/Container';
import StandardContent from 'components/StandardContent';
import BackButton from 'components/BackButton';
import Image from './assets/lounaan-abc.jpg';
import { useTranslation, useLanguage, Language } from 'features/translator';

import FinnishContent from './FinnishContent';
import EnglishContent from './EnglishContent';
import SwedishContent from './SwedishContent';

const LunchABCPage: React.FunctionComponent = () => {
  const translation = useTranslation();
  const [activeLanguage] = useLanguage();

  return (
    <Page name="Lounaan ABC" title={[translation.get('infoPage', 'title'), translation.get('lunchABCPage', 'title')]}>
      <div className={styles.component}>
        <ScrollWrapper>
          <Container>
            <div className={styles.back}>
              <BackButton></BackButton>
            </div>
            <div className={styles.header}>
              <h1 className={styles.title}>{translation.get('lunchABCPage', 'title')}</h1>
              <div className={styles.image} style={{ backgroundImage: `url(${Image})` }} />
            </div>
            <div className={styles.content}>
              <StandardContent>{getContent(activeLanguage)}</StandardContent>
            </div>
          </Container>
        </ScrollWrapper>
      </div>
    </Page>
  );
};

const getContent = (language: Language) => {
  switch (language) {
    case 'fi': {
      return <FinnishContent />;
    }
    case 'sv': {
      return <SwedishContent />;
    }
    case 'en': {
      return <EnglishContent />;
    }
    default: {
      return null;
    }
  }
};

export default LunchABCPage;
