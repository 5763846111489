import React from 'react';
import { motion } from 'framer-motion';
import styles from './SwitchButton.module.scss';

interface ISwitchButton {
  isActive: boolean;
  clickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const SwitchButton: React.FunctionComponent<ISwitchButton> = ({ isActive, clickHandler }) => {
  return (
    <button className={styles.component} onClick={clickHandler}>
      <motion.div
        className={styles.background}
        initial={isActive ? 'active' : 'inactive'}
        animate={isActive ? 'active' : 'inactive'}
        variants={{
          active: { backgroundColor: '#4FDD75', borderColor: '#4FDD75' },
          inactive: { backgroundColor: '#FFFFFF', borderColor: '#C8C8C8' },
        }}
        transition={{ duration: 0.2 }}
      />
      <motion.div
        className={styles.button}
        initial={isActive ? 'active' : 'inactive'}
        animate={isActive ? 'active' : 'inactive'}
        variants={{
          active: { x: 22, borderColor: '#4FDD75' },
          inactive: { x: 0, borderColor: '#C8C8C8' },
        }}
      />
    </button>
  );
};

export default SwitchButton;
