import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './RestaurantList.module.scss';

import { getFilteredRestaurants } from 'store/restaurants/selectors';
import { toggleRestaurantFilter, addRestaurantFilters, removeRestaurantFilters } from 'store/restaurants/actions';
import { IRestaurant } from 'pages/Lunch/Restaurant';
import SwitchSetting from 'components/SwitchSetting';

import ToggleAll from './ToggleAll';

interface IRestaurantList {
  restaurants: IRestaurant[];
  area: string;
}

const RestaurantList: React.FunctionComponent<IRestaurantList> = ({ area, restaurants }) => {
  const dispatch = useDispatch();
  const filteredRestaurants = useSelector(getFilteredRestaurants);
  const restaurantIds = restaurants.map((restaurant) => restaurant.id);

  return (
    <div className={styles.component}>
      <ToggleAll
        area={area}
        allSelected={restaurantIds.every((id) => filteredRestaurants.includes(id))}
        addHandler={() => dispatch(addRestaurantFilters(restaurantIds))}
        removeHandler={() => dispatch(removeRestaurantFilters(restaurantIds))}
      />

      {restaurants.map((restaurant) => (
        <SwitchSetting
          key={restaurant.id}
          title={restaurant.title}
          isActive={filteredRestaurants.includes(restaurant.id)}
          clickHandler={() => dispatch(toggleRestaurantFilter(restaurant.id))}
        />
      ))}
    </div>
  );
};

export default RestaurantList;
