import React from 'react';
import classNames from 'classnames';
import styles from './LanguageRadioSelector.module.scss';

import { Language, useLanguage } from 'features/translator';

interface Props {
  value: Language;
  label: string;
}

const LanguageRadioSelector: React.FunctionComponent<Props> = ({ value, label }) => {
  const [activeLanguage, setActiveLanguage] = useLanguage();
  const isChecked = activeLanguage === value;

  const handleChange = () => {
    setActiveLanguage(value);
  };

  const componentClass = classNames(styles.component, {
    [styles.checked]: isChecked,
  });

  return (
    <div className={componentClass}>
      <input
        id={value}
        type="radio"
        name="language"
        value={value}
        onChange={handleChange}
        checked={isChecked}
        className={styles.input}
      />
      <label htmlFor={value} className={styles.label}>
        {label}
      </label>
    </div>
  );
};

export default LanguageRadioSelector;
