import React from 'react';
import styles from './Navigation.module.scss';
import NavigationButton from './NavigationButton';
import { useTranslation } from 'features/translator';

const Navigation: React.FunctionComponent = () => {
  const translation = useTranslation();

  return (
    <nav className={styles.component}>
      <ul className={styles.list}>
        <li className={styles.item}>
          <NavigationButton label={translation.get('infoPage', 'navigationTitle')} to="/info" />
        </li>
        <li className={styles.item}>
          <NavigationButton label={translation.get('lunchPage', 'navigationTitle')} to="/" />
        </li>
        <li className={styles.item}>
          <NavigationButton label={translation.get('settingsPage', 'navigationTitle')} to="/asetukset" />
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
