import React from 'react';
import { useSelector } from 'react-redux';
import styles from './RestaurantDetails.module.scss';

import { getRestaurantDetails } from 'store/restaurants/selectors';
import { IRestaurant } from './Restaurant';
import OpeningTimes from 'components/OpeningTimes';
import Button from 'components/Button';
import { useTranslation } from 'features/translator';

const RestaurantDetails: React.FunctionComponent = () => {
  const restaurant: IRestaurant = useSelector(getRestaurantDetails);
  const { title, address, menuData } = restaurant || {};
  const { visitingHours, email, phone, description, feedback_address } = menuData || {};
  const accessibility = parseAccessibility(description);
  const translation = useTranslation();

  return (
    <div className={styles.component}>
      <h1 className={styles.title}>{title}</h1>
      {!!address && (
        <>
          <div className={styles.address}>{address}</div>
          <a
            href={`https://www.google.com/maps/place/${address}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.maplink}>
            {translation.get('general', 'mapLink')}
          </a>
        </>
      )}
      {!!visitingHours && (
        <div className={styles.visitingHours}>
          <OpeningTimes visitingHours={visitingHours} />
        </div>
      )}

      <div className={styles.section}>
        <h3 className={styles.subtitle}>{translation.get('general', 'contact')}</h3>
        {!!phone && <div className={styles.phone} dangerouslySetInnerHTML={{ __html: phone.replace('  ', '<br>') }} />}
        <a href={`mailto: ${email}`} className={styles.email}>
          {email}
        </a>
      </div>
      <div className={styles.section}>
        <h3 className={styles.subtitle}>{translation.get('general', 'accessibility')}</h3>
        <div dangerouslySetInnerHTML={{ __html: accessibility }} />
      </div>
      {!!feedback_address && (
        <div className={styles.feedback}>
          <Button
            text={translation.get('general', 'feedback')}
            url={feedback_address}
            target="_blank"
            theme="white"
            size="medium"
            border={true}
            icon="chevron-right"></Button>
        </div>
      )}
    </div>
  );
};

/**
 * Let's try to figure out how to get the accessibility data in a sane format...
 */
const parseAccessibility = (str?: string): string => {
  if (!str) {
    return '';
  }

  let accessibility = str;

  // first let's remove unnecessary extra words, including typos they make.
  accessibility = accessibility.replace('Esteettömyys', '').replace('Esteettömys', '');

  // let's remove <br> from the beginning of the remaining string
  if (accessibility.startsWith('<br />')) {
    accessibility = accessibility.substr(6);
  }

  // finally, we don't need any "french lines", they seem to use multiple formats
  accessibility = accessibility.replace('- ', '').replace('- ', '');

  return accessibility;
};

export default RestaurantDetails;
