import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import analytics from 'utils/analytics';
import { getAnalyticsPermission } from 'store/system/selectors';
import { getAllowedDietsString } from 'store/foods/selectors';
import { getFilteredRestaurantsString } from 'store/restaurants/selectors';

interface Props {
  name: string;
}

const PageView: React.FunctionComponent<Props> = ({ name }) => {
  const location = useLocation();
  const analyticsPermission = useSelector(getAnalyticsPermission);
  const allowedDietsString = useSelector(getAllowedDietsString);
  const filteredRestaurantsString = useSelector(getFilteredRestaurantsString);
  const diets = useRef(allowedDietsString);
  const restaurants = useRef(filteredRestaurantsString);

  useEffect(() => {
    analytics.pageView(location.pathname, name, diets.current, restaurants.current);
  }, [location, name, analyticsPermission]);

  return null;
};

export default PageView;
