import localForage from 'localforage';
import { createMigrate, REHYDRATE } from 'redux-persist';
import migrations from './migrations';

import { CONSENT_ASKED, SET_ANALYTICS_PERMISSION } from './actions';
import { setAnalyticsPermission } from 'utils/analytics';

/**
 * Redux Persist configuration object. Add keys
 * you want to remain in storage into the whitelist array.
 *
 * The version number is used in database migrations.
 */
export const systemPersistConfig = {
  key: 'system',
  version: 0,
  storage: localForage,
  whitelist: ['isConsentAsked', 'analyticsPermission'],
  migrate: createMigrate(migrations, { debug: process.env.NODE_ENV === 'development' }),
};

interface IState {
  isConsentAsked: boolean;
  analyticsPermission: boolean;
}

const initialState: IState = {
  isConsentAsked: false,
  analyticsPermission: false,
};

export default function (state: IState = initialState, action: any) {
  switch (action.type) {
    case REHYDRATE: {
      if (action.key === 'system') {
        const { analyticsPermission } = action.payload || {};

        if (analyticsPermission === true) {
          setAnalyticsPermission(analyticsPermission);
        }
      }

      return state;
    }
    case CONSENT_ASKED: {
      return {
        ...state,
        isConsentAsked: true,
      };
    }
    case SET_ANALYTICS_PERMISSION: {
      const { analyticsPermission } = action.payload || {};

      setAnalyticsPermission(analyticsPermission);

      return {
        ...state,
        analyticsPermission,
      };
    }
    default:
      return state;
  }
}
