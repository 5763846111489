export const CONSENT_ASKED: string = '[system] Consent Asked';
export const SET_ANALYTICS_PERMISSION: string = '[system] Set Analytics Permission';

export const consentAsked = () => ({
  type: CONSENT_ASKED,
});

export const setAnalyticsPermission = (analyticsPermission: boolean) => ({
  type: SET_ANALYTICS_PERMISSION,
  payload: {
    analyticsPermission,
  },
});
