import React from 'react';
import styles from './LanguagePage.module.scss';

import Page from 'components/Page';
import Container from 'components/Container';
import ScrollWrapper from 'components/ScrollWrapper';
import StandardContent from 'components/StandardContent';
import BackButton from 'components/BackButton';
import LanguageRadioSelector from './LanguageRadioSelector';
import { useTranslation } from 'features/translator';

const LanguagePage: React.FunctionComponent = () => {
  const translation = useTranslation();

  return (
    <Page name="Kieli" title={[translation.get('settingsPage', 'title'), translation.get('languagePage', 'title')]}>
      <div className={styles.component}>
        <ScrollWrapper>
          <Container>
            <div className={styles.back}>
              <BackButton></BackButton>
            </div>
            <div className={styles.header}>
              <StandardContent>
                <h1>Kieli / Språk / Language</h1>
              </StandardContent>
            </div>
            <div className={styles.main}>
              <LanguageRadioSelector value="fi" label="Suomi" />
              <LanguageRadioSelector value="sv" label="Svenska" />
              <LanguageRadioSelector value="en" label="English" />
            </div>
          </Container>
        </ScrollWrapper>
      </div>
    </Page>
  );
};

export default LanguagePage;
