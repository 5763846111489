import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';
import { bundleDatesIntoWeeks } from 'utils/date';
import { getDateData } from '../data/selectors';

export const getSelectedDate = (state: RootState): string => state.date.selected;
export const checkShowDateSelector = (state: RootState): boolean => state.date.showModal;
export const getToday = (state: RootState): string => state.date.today;

/**
 * Memoized selectors
 * https://react-redux.js.org/api/hooks#using-memoizing-selectors
 */
export const getWeeks = createSelector(getDateData, (dates) => {
  return bundleDatesIntoWeeks(dates);
});
