import React from 'react';
import { useSelector } from 'react-redux';

import { IRestaurant } from './Restaurant';
import Area from './Area';
import { arrangeRestaurantsInsideAreas } from 'utils/restaurants';
import { getRestaurantData } from 'store/data/selectors';

const RestaurantsListedInsideAreas: React.FunctionComponent = () => {
  const restaurants: IRestaurant[] = useSelector(getRestaurantData);
  const areas = arrangeRestaurantsInsideAreas(restaurants);

  return (
    <>
      {areas.map((area) => (
        <Area {...area} key={area.id}></Area>
      ))}
    </>
  );
};

export default RestaurantsListedInsideAreas;
