import React from 'react';
import { useSelector } from 'react-redux';
import styles from './OpeningTimesPage.module.scss';

import Page from 'components/Page';
import ScrollWrapper from 'components/ScrollWrapper';
import Container from 'components/Container';
import BackButton from 'components/BackButton';
import RestaurantTimes from './RestaurantTimes';
import { getRestaurantOpeningTimes } from 'store/restaurants/selectors';
import { useTranslation } from 'features/translator';

const OpeningTimesPage: React.FunctionComponent = () => {
  const restaurantOpeningTimes = useSelector(getRestaurantOpeningTimes);
  const translation = useTranslation();

  return (
    <Page
      name="Aukioloajat"
      title={[translation.get('infoPage', 'title'), translation.get('openingTimesPage', 'title')]}>
      <div className={styles.component}>
        <ScrollWrapper>
          <Container>
            <div className={styles.back}>
              <BackButton></BackButton>
            </div>
            <div className={styles.header}>
              <h1 className={styles.title}>{translation.get('openingTimesPage', 'title')}</h1>
            </div>
            <div className={styles.content}>
              {restaurantOpeningTimes.map((each, index) => (
                <RestaurantTimes key={index} {...each} />
              ))}
            </div>
          </Container>
        </ScrollWrapper>
      </div>
    </Page>
  );
};

export default OpeningTimesPage;
