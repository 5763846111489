import localForage from 'localforage';
import { createMigrate } from 'redux-persist';
import migrations from './migrations';
import { TOGGLE_AREA_EXPANDED } from './actions';

/**
 * Redux Persist configuration object. Add keys
 * you want to remain in storage into the whitelist array.
 *
 * The version number is used in database migrations.
 */
export const areasPersistConfig = {
  key: 'areas',
  version: 0,
  storage: localForage,
  whitelist: ['expanded'],
  migrate: createMigrate(migrations, { debug: process.env.NODE_ENV === 'development' }),
};

/**
 * Types
 */
interface IState {
  expanded: number[];
}

const initialState: IState = {
  expanded: [],
};

/**
 * Reducer
 */
export default function (state: IState = initialState, action: any) {
  switch (action.type) {
    case TOGGLE_AREA_EXPANDED: {
      return {
        ...state,
        expanded: state.expanded.includes(action.payload)
          ? state.expanded.filter((id) => id !== action.payload)
          : state.expanded.concat(action.payload),
      };
    }
    default:
      return state;
  }
}
