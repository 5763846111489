import React from 'react';
import classNames from 'classnames';

import styles from './FoodDiets.module.scss';
import { useTranslation } from 'features/translator';

interface IFoodDiets {
  diets: string[];
  size?: 'small' | 'large';
  hasUnsuitables?: boolean;
}

const FoodDiets: React.FunctionComponent<IFoodDiets> = ({ diets, size = 'small', hasUnsuitables = false }) => {
  const translation = useTranslation();

  if (!diets.length) {
    return null;
  }

  const componentClass = classNames(styles.component, styles[size], {
    [styles.hasUnsuitables]: hasUnsuitables,
    [styles.allowWrap]: diets.length > 4,
  });

  return (
    <div className={componentClass}>
      {diets.map((diet) => {
        const dietLabel = diet.replace('pyydä', `<span>${translation.get('foods', 'request')}</span>`);

        return <div key={diet} className={styles.diet} dangerouslySetInnerHTML={{ __html: dietLabel }} />;
      })}
    </div>
  );
};

export default FoodDiets;
