import React from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import styles from './DateSelectorTrigger.module.scss';

import { isSameDay, getShortDisplayDate } from 'utils/date';
import { getToday, getSelectedDate } from 'store/date/selectors';
import { useLanguage, useTranslation } from 'features/translator';

interface Props {
  clickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const DateSelectorTrigger: React.FunctionComponent<Props> = ({ clickHandler }) => {
  const date = useSelector(getSelectedDate);
  const today = useSelector(getToday);
  const isToday = isSameDay(date, today);
  const [activeLanguage] = useLanguage();
  const translation = useTranslation();

  return (
    <motion.button
      whileTap={{ scale: 0.9 }}
      whileHover={{ scale: 1.05 }}
      className={styles.component}
      onClick={clickHandler}
      aria-label={translation.get('lunchPage', 'dateSelectorTriggerAriaLabel')}>
      {isToday && <div className={styles.indicator} />}
      <span className={styles.date}>{getShortDisplayDate(date, activeLanguage)}</span>
    </motion.button>
  );
};

export default DateSelectorTrigger;
