import { useContext, useEffect } from 'react';
import { UnsureRoute, RouteRelationship, RoutePosition, RoutePaths } from './types';
import { MotionRouterContext } from './MotionRouterContext';

export interface RouteRelationshipFinder {
  setPaths: (newPaths: string[]) => void;
  setRoutes: (routeA: UnsureRoute, routeB: UnsureRoute) => this;
  find: () => RouteRelationship;
}

let routeRelationshipFinder: RouteRelationshipFinder | undefined;

export function useRouteRelationshipFinder() {
  const context = useContext(MotionRouterContext);
  const { routePaths } = context || {};

  if (!routeRelationshipFinder) {
    routeRelationshipFinder = createRouteRelationshipFinder();
    routeRelationshipFinder.setPaths(routePaths || []);
  }

  useEffect(() => {
    if (routeRelationshipFinder) {
      routeRelationshipFinder.setPaths(routePaths || []);
    }
  }, [routePaths]);

  return routeRelationshipFinder;
}

function createRouteRelationshipFinder(): RouteRelationshipFinder {
  let routePaths: RoutePaths = [];
  let routeA: UnsureRoute;
  let routeB: UnsureRoute;

  const setPaths = (paths: RoutePaths) => {
    routePaths = paths;
  };

  const setRoutes = (a: UnsureRoute, b: UnsureRoute) => {
    routeA = a;
    routeB = b;
  };

  const find = (): RouteRelationship => {
    if (!routeA || !routeB || !routePaths.length) {
      return 'unknown';
    }

    const positionA = findRoutePosition(routeA);
    const positionB = findRoutePosition(routeB);
    return getRouteRelationship(positionA, positionB);
  };

  const findRoutePosition = (route: string): RoutePosition => ({
    level: findRouteLevel(route),
    order: findRouteOrder(route),
  });

  const findRouteLevel = (route: string) => {
    return route.split('/').length;
  };

  const findRouteOrder = (route: string) => {
    return routePaths.findIndex((path) => path === route);
  };

  const getRouteRelationship = (a: RoutePosition, b: RoutePosition): RouteRelationship => {
    if (a.level > b.level) {
      return 'deeper';
    }

    if (a.level < b.level) {
      return 'shallower';
    }

    if (a.order === -1 || b.order === -1) {
      return 'unknown';
    }

    if (a.order > b.order) {
      return 'next';
    }

    if (a.order < b.order) {
      return 'previous';
    }

    return 'same';
  };

  const routeRelationshipFinder = {
    setPaths,
    setRoutes(a, b) {
      setRoutes(a, b);
      return this;
    },
    find,
  } as RouteRelationshipFinder;
  return routeRelationshipFinder;
}
