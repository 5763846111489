import { IRestaurant } from 'pages/Lunch/Restaurant';
import { IArea } from 'pages/Lunch/Area';

export const arrangeRestaurantsInsideAreas = (restaurants: IRestaurant[]) => {
  return restaurants.reduce((accumulator, restaurant: IRestaurant) => {
    const { location } = restaurant || [];
    const { id, name } = location[0] || {};
    const area = accumulator.find((area) => area.id === id);

    if (!area) {
      accumulator.push({ id: id, name: name, restaurants: [restaurant] });
    } else {
      area.restaurants.push(restaurant);
    }

    return accumulator;
  }, [] as IArea[]);
};
