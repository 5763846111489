export const TITLE = 'UniCafe Menu';
export const SEPARATOR = '–';

export const getSEOTitle = (names?: string[]) => {
  if (!names) {
    return TITLE;
  }

  let str = TITLE;

  names.forEach((name) => {
    str += ` ${SEPARATOR} ${name}`;
  });

  return str;
};
