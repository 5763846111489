import localForage from 'localforage';
import { createMigrate } from 'redux-persist';
import migrations from './migrations';

import { TOGGLE_DIET_FILTER } from './actions';

/**
 * Redux Persist configuration object. Add keys
 * you want to remain in storage into the whitelist array.
 *
 * The version number is used in database migrations.
 */
export const foodsPersistConfig = {
  key: 'foods',
  version: 0,
  storage: localForage,
  whitelist: ['allowedDiets'],
  migrate: createMigrate(migrations, { debug: process.env.NODE_ENV === 'development' }),
};

/**
 * Types
 */
interface IState {
  allowedDiets: string[];
}

const initialState: IState = {
  allowedDiets: [],
};

/**
 * Reducer
 */
export default function (state: IState = initialState, action: any) {
  switch (action.type) {
    case TOGGLE_DIET_FILTER: {
      const allowedDiets = state.allowedDiets.includes(action.payload)
        ? state.allowedDiets.filter((id) => id !== action.payload)
        : state.allowedDiets.concat(action.payload);

      return {
        ...state,
        allowedDiets: allowedDiets,
      };
    }
    default:
      return state;
  }
}
