import { useRoutes } from './use-routes';

/**
 * The default NavLink "isActive" behavior is such that "/" route
 * is always active, even if you are in "/info" for example.
 *
 * This custom hook considers root as it's own route.
 */
export function useIsActive(to: string) {
  const { currentRoute } = useRoutes();
  let isActive: boolean;
  const isRoot: boolean = to === '/';

  if (isRoot) {
    isActive = currentRoute === to;
  } else {
    isActive = currentRoute.startsWith(to);
  }

  return isActive;
}
