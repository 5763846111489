import React from 'react';
import classNames from 'classnames';
import styles from './OpeningTime.module.scss';

import { Time, Times } from './types';
import { useTranslation } from 'features/translator';

export interface Props {
  type: 'business' | 'lunch' | 'breakfast' | 'bistro';
  items: Times | false;
}

const OpeningTime: React.FunctionComponent<Props> = ({ type, items }) => {
  const times = getTimeComponents(items);
  const translation = useTranslation();

  if (!times) {
    return null;
  }

  return (
    <div className={styles.component}>
      <h5 className={styles.title}>{`${translation.get('openingHours', type)}:`}</h5>
      {times}
    </div>
  );
};

const getTimeComponents = (visitingHours: Times | false): any => {
  if (!visitingHours) return false;

  const { items } = visitingHours || {};

  // Filter out the unnecessary "exceptionUndefinedTime" -key
  const filteredItems = items.filter((item) => typeof item.exceptionUndefinedTime === 'undefined');

  return filteredItems.map((item: any, index: number) => {
    return <TimeComponent {...item} key={index}></TimeComponent>;
  });
};

const TimeComponent: React.FunctionComponent<Time> = ({ label, hours, exception }) => {
  const componentClass = classNames(styles.time, {
    [styles.isException]: exception,
  });

  return (
    <div className={componentClass}>
      <div className={styles.label}>{label}</div>
      <div className={styles.hours}>{hours}</div>
    </div>
  );
};

export default OpeningTime;
