import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import styles from './LunchPage.module.scss';

import Page from 'components/Page';
import { toggleDateModal } from 'store/date/actions';
import { checkShowDateSelector } from 'store/date/selectors';
import { checkShowRestaurantDetails, checkShowFoodDetails } from 'store/restaurants/selectors';
import { closeRestaurantDetails, closeFoodDetails } from 'store/restaurants/actions';

import RestaurantList from './RestaurantList';
import ScrollWrapper from 'components/ScrollWrapper';
import DateSelectorTrigger from './DateSelectorTrigger';
import DateSelector from './DateSelector';
import Modal, { ModalBackground } from 'components/Modal';
import FiltersLink from './FiltersLink';
import RestaurantDetails from './RestaurantDetails';
import FoodDetails from './FoodDetails';
import SuggestAreaSelection from './SuggestAreaSelection';
import Logo from './Logo';
import DataReloader from './DataReloader';

const LunchPage: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const showDateSelector = useSelector(checkShowDateSelector);
  const showRestaurantDetails = useSelector(checkShowRestaurantDetails);
  const showFoodDetails = useSelector(checkShowFoodDetails);
  const showModal = showDateSelector || showRestaurantDetails || showFoodDetails;

  return (
    <Page name="Lounas" title={[]}>
      <div className={styles.component}>
        <ScrollWrapper lock={showModal}>
          <div className={styles.header}>
            <DateSelectorTrigger clickHandler={() => dispatch(toggleDateModal())} />
            <DataReloader />
            <FiltersLink />
          </div>
          <div className={styles.main}>
            <Logo />
            <SuggestAreaSelection />
            <RestaurantList />
          </div>
        </ScrollWrapper>

        <AnimatePresence>
          {showModal && <ModalBackground></ModalBackground>}
          {showDateSelector && (
            <Modal key="date-selector" direction="right" closeHandler={() => dispatch(toggleDateModal())}>
              <DateSelector></DateSelector>
            </Modal>
          )}
          {showRestaurantDetails && (
            <Modal key="restaurant-details" direction="up" closeHandler={() => dispatch(closeRestaurantDetails())}>
              <RestaurantDetails></RestaurantDetails>
            </Modal>
          )}
          {showFoodDetails && (
            <Modal key="food-details" direction="up" closeHandler={() => dispatch(closeFoodDetails())}>
              <FoodDetails></FoodDetails>
            </Modal>
          )}
        </AnimatePresence>
      </div>
    </Page>
  );
};

export default LunchPage;
