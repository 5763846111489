import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import styles from './DateSelector.module.scss';

import { IWeek, findSelectedWeekIndex, getWeekContext, isSameDay, isSameDayOrLater } from 'utils/date';
import { getWeeks, getSelectedDate, getToday } from 'store/date/selectors';
import DateButton from './DateButton';

import { ReactComponent as ArrowLeft } from './assets/arrow-left.svg';
import { ReactComponent as ArrowRight } from './assets/arrow-right.svg';
import { useTranslation } from 'features/translator';

const DateSelector = () => {
  const weeks = useSelector(getWeeks);
  const today = useSelector(getToday);
  const selectedDate = useSelector(getSelectedDate);
  const translation = useTranslation();
  const [activeSlide, setActiveSlide] = useState(findSelectedWeekIndex(selectedDate, weeks));
  const showPreviousButton = activeSlide > 0;
  const showNextButton = activeSlide < weeks.length - 1;

  return (
    <div className={styles.component}>
      <h1 className={styles.title}>{translation.get('dateSelector', 'title')}</h1>
      <motion.div
        initial={false}
        animate={{ x: `${activeSlide * -100}%` }}
        transition={{ type: 'spring', mass: 0.25 }}
        className={styles.slider}>
        {weeks.map((week: IWeek) => {
          return (
            <div key={week.number} className={styles.week}>
              <WeekLabel week={week} today={today} />
              <ul className={styles.list}>
                {week.dates.map((date: string, index) => {
                  const isToday = isSameDay(date, today);
                  const isSelected = isSameDay(date, selectedDate);

                  if (isSameDayOrLater(date, today)) {
                    return (
                      <li key={index} className={styles.item}>
                        <DateButton date={date} isSelected={isSelected} isToday={isToday} />
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}
              </ul>
            </div>
          );
        })}
      </motion.div>
      <div className={styles.controls}>
        {showPreviousButton && (
          <button
            className={styles.previous}
            onClick={() => {
              setActiveSlide(activeSlide - 1);
            }}>
            <ArrowLeft />
          </button>
        )}

        {showNextButton && (
          <button
            className={styles.next}
            onClick={() => {
              setActiveSlide(activeSlide + 1);
            }}>
            <ArrowRight />
          </button>
        )}
      </div>
    </div>
  );
};

interface WeekLabelProps {
  week: IWeek;
  today: string;
}

const WeekLabel: React.FunctionComponent<WeekLabelProps> = ({ week, today }) => {
  const translation = useTranslation();
  const weekContext = getWeekContext(week, today);
  const label = () => {
    switch (weekContext) {
      case 'this': {
        return translation.get('weeks', 'thisWeek');
      }
      case 'next': {
        return translation.get('weeks', 'nextWeek');
      }
      default: {
        return `${translation.get('weeks', 'weekNumberPrefix')} ${week.number}`;
      }
    }
  };

  return <h2 className={styles.label}>{label()}</h2>;
};

export default DateSelector;
