import React from 'react';
import { motion } from 'framer-motion';
import styles from './CloseButton.module.scss';

import { ReactComponent as CloseIcon } from './assets/close-icon.svg';
import { useTranslation } from 'features/translator';

interface ICloseButton {
  closeHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const CloseButton: React.FunctionComponent<ICloseButton> = ({ closeHandler }) => {
  const translation = useTranslation();

  return (
    <motion.button
      whileTap={{ scale: 0.9 }}
      whileHover={{ scale: 1.1 }}
      className={styles.component}
      onClick={closeHandler}
      tabIndex={0}
      aria-label={translation.get('general', 'close')}>
      <CloseIcon />
    </motion.button>
  );
};

export default CloseButton;
