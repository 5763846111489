import React from 'react';
import { MotionNavLink, useIsActive } from 'features/motion-router';
import styles from './NavigationButton.module.scss';

import NavigationIcon from './NavigationIcon';

interface INavigationButton {
  label: string;
  to: string;
}

const NavigationButton: React.FunctionComponent<INavigationButton> = ({ label, to }) => {
  const isActive = useIsActive(to);

  return (
    <MotionNavLink className={styles.component} to={to}>
      <div className={styles.icon}>
        <NavigationIcon label={label} active={isActive} />
      </div>
      <div className={styles.label}>{label}</div>
    </MotionNavLink>
  );
};

export default NavigationButton;
