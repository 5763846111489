import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styles from './DietFilters.module.scss';

import { toggleDietFilter } from 'store/foods/actions';
import { getAllowedDiets } from 'store/foods/selectors';
import SwitchSetting from 'components/SwitchSetting';
import { useTranslation } from 'features/translator';

const DietFilters = () => {
  const dispatch = useDispatch();
  const translation = useTranslation();
  const allowedDiets = useSelector(getAllowedDiets);

  const DIETS = {
    VL: translation.get('foodsPage', 'lowLactose'),
    L: translation.get('foodsPage', 'lactoseFree'),
    M: translation.get('foodsPage', 'milkFree'),
    G: translation.get('foodsPage', 'glutenFree'),
    VE: translation.get('foodsPage', 'vegan'),
  };

  return (
    <div className={styles.component}>
      {Object.entries(DIETS).map(([key, value]) => (
        <SwitchSetting
          key={key}
          title={`${value} (${key})`}
          isActive={allowedDiets.includes(key)}
          clickHandler={() => dispatch(toggleDietFilter(key))}
        />
      ))}
    </div>
  );
};

export default DietFilters;
