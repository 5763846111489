import React from 'react';
import { motion } from 'framer-motion';
import styles from './ModalBackground.module.scss';

export const ModalBackground = () => {
  return (
    <motion.div
      initial={'initial'}
      animate={'open'}
      exit={'closed'}
      variants={{
        initial: { opacity: 0 },
        open: { opacity: 1 },
        closed: { opacity: 0 },
      }}
      transition={{ duration: 0.15 }}
      key="modal-background"
      className={styles.component}
    />
  );
};

export default ModalBackground;
