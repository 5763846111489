import React, { useState, useMemo } from 'react';
import { MotionRouterContext } from './MotionRouterContext';

export const MotionRouterProvider: React.FunctionComponent = ({ children }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [routePaths, setRoutePaths] = useState([] as string[]);

  const context = useMemo(() => {
    return {
      isAnimating,
      setIsAnimating,
      routePaths,
      setRoutePaths,
    };
  }, [isAnimating, routePaths]);

  return <MotionRouterContext.Provider value={context}>{children}</MotionRouterContext.Provider>;
};
