import React from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import styles from './Logo.module.scss';

import { checkSuggestAreaSelection } from 'store/restaurants/selectors';
import src from './assets/logo-unicafe.png';

const Logo = () => {
  const suggestAreaSelection = useSelector(checkSuggestAreaSelection);

  return (
    <motion.div
      className={styles.component}
      initial={suggestAreaSelection ? 'active' : 'inactive'}
      animate={suggestAreaSelection ? 'active' : 'inactive'}
      variants={{
        active: { height: 'auto' },
        inactive: { height: 0 },
      }}>
      <div className={styles.wrapper}>
        <motion.img
          src={src}
          alt="UniCafe"
          className={styles.image}
          initial={suggestAreaSelection ? 'active' : 'inactive'}
          animate={suggestAreaSelection ? 'active' : 'inactive'}
          variants={{
            active: { translateY: 0 },
            inactive: { translateY: -25 },
          }}
        />
      </div>
    </motion.div>
  );
};

export default Logo;
