import React from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import styles from './Tab.module.scss';
import { useTranslation } from 'features/translator';

interface ITab {
  title: string;
  isActive: boolean;
  clickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Tab: React.FunctionComponent<ITab> = ({ title, isActive, clickHandler }) => {
  const translation = useTranslation();
  const componentClass = classNames(styles.component, {
    [styles.isActive]: isActive,
  });

  const displayTitle = () => {
    switch (title) {
      case 'Keskusta': {
        return translation.get('areas', 'keskusta');
      }
      case 'Meilahti': {
        return translation.get('areas', 'meilahti');
      }
      case 'Viikki': {
        return translation.get('areas', 'viikki');
      }
      case 'Kumpula': {
        return translation.get('areas', 'kumpula');
      }
      default: {
        return title;
      }
    }
  };

  return (
    <motion.button whileTap={{ scale: 0.9 }} className={componentClass} onClick={clickHandler}>
      {displayTitle()}
    </motion.button>
  );
};

export default Tab;
