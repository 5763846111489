import React from 'react';
import styles from './ToggleAll.module.scss';

import Button from 'components/Button';
import { useTranslation } from 'features/translator';

interface Props {
  area: string;
  allSelected: boolean;
  addHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  removeHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ToggleAll: React.FunctionComponent<Props> = ({ area, allSelected, addHandler, removeHandler }) => {
  const translation = useTranslation();
  const cta = !allSelected
    ? translation.get('restaurantsPage', 'chooseAll')
    : translation.get('restaurantsPage', 'removeAll');

  const suffix = () => {
    switch (area) {
      case 'Keskusta': {
        return translation.get('restaurantsPage', 'keskustaSuffix');
      }
      case 'Viikki': {
        return translation.get('restaurantsPage', 'viikkiSuffix');
      }
      case 'Meilahti': {
        return translation.get('restaurantsPage', 'meilahtiSuffix');
      }
      case 'Kumpula': {
        return translation.get('restaurantsPage', 'kumpulaSuffix');
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className={styles.component}>
      <Button
        text={`${cta} ${suffix()}`}
        clickHandler={!allSelected ? addHandler : removeHandler}
        theme={!allSelected ? 'greenBright' : 'red'}
      />
    </div>
  );
};

export default ToggleAll;
