import React from 'react';
import { MotionLink } from 'features/motion-router';
import styles from './InfoPage.module.scss';

import Page from 'components/Page';
import ScrollWrapper from 'components/ScrollWrapper';
import Container from 'components/Container';
import MenuButton from 'components/MenuButton';
import ButtonGroup from 'components/ButtonGroup';
import Highlight from 'components/Highlight';

import { ReactComponent as Clock } from './assets/clock.svg';
import { ReactComponent as Feedback } from './assets/feedback.svg';
import { ReactComponent as Lock } from './assets/lock.svg';
import { ReactComponent as Question } from './assets/question.svg';
import { ReactComponent as Tag } from './assets/tag.svg';
import HighlightImage from './LunchABC/assets/lounaan-abc.jpg';
import { useTranslation } from 'features/translator';

const InfoPage: React.FunctionComponent = () => {
  const translation = useTranslation();

  return (
    <Page name="Info" title={[translation.get('infoPage', 'title')]}>
      <div className={styles.component}>
        <ScrollWrapper>
          <div className={styles.wrapper}>
            <Container>
              <div className={styles.content}>
                <ButtonGroup>
                  <MotionLink to="/info/lounaan-abc">
                    <Highlight
                      title={translation.get('infoPage', 'highlightTitle')}
                      text={translation.get('infoPage', 'highlightText')}
                      imageUrl={HighlightImage}
                      linkText={translation.get('infoPage', 'highlightLinkText')}
                    />
                  </MotionLink>
                </ButtonGroup>
                <ButtonGroup>
                  <MotionLink to="/info/aukioloajat">
                    <MenuButton text={translation.get('openingTimesPage', 'navigationTitle')} icon={Clock} />
                  </MotionLink>
                  <MotionLink to="/info/hinnasto">
                    <MenuButton text={translation.get('pricingPage', 'navigationTitle')} icon={Tag} />
                  </MotionLink>
                  <MotionLink to="/info/usein-kysyttya">
                    <MenuButton text={translation.get('FAQPage', 'navigationTitle')} icon={Question} />
                  </MotionLink>
                  <MotionLink to="/info/yksityisyys">
                    <MenuButton text={translation.get('privacyPage', 'navigationTitle')} icon={Lock} />
                  </MotionLink>
                </ButtonGroup>
                <ButtonGroup>
                  <MotionLink to="/info/palaute">
                    <MenuButton
                      text={translation.get('feedbackPage', 'navigationTitle')}
                      theme={'green'}
                      icon={Feedback}
                    />
                  </MotionLink>
                </ButtonGroup>
              </div>
            </Container>
          </div>
        </ScrollWrapper>
      </div>
    </Page>
  );
};

export default InfoPage;
