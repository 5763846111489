import React from 'react';
import classNames from 'classnames';

import styles from './FoodName.module.scss';

export const getFoodName = (text: string): React.ReactElement => {
  // split whatever comes after the first comma as a supplementary name
  let textArray = text.split(/, ?(.+)?/);

  if (textArray.length > 2 && textArray[0] && textArray[1]) {
    /**
     * There are cases where the text has been inputted a bit weird...
     * so we're going to try to figure out if what follows the comma is
     * actually a word.
     */
    if (textArray[1].length < 5) {
      return <>{text}</>;
    } else {
      return (
        <>
          {textArray[0]}
          <span className={styles.supplementary}>{textArray[1]}</span>
        </>
      );
    }
  } else {
    return <>{textArray[0]}</>;
  }
};

export interface IFoodName {
  text: string;
  size?: 'small' | 'large';
}

const FoodName: React.FunctionComponent<IFoodName> = ({ text, size = 'small' }) => {
  const name = getFoodName(text);
  const componentClass = classNames({
    [styles.component]: true,
    [styles.large]: size === 'large',
    [styles.small]: size === 'small',
  });

  if (size === 'large') {
    return <h1 className={componentClass}>{name}</h1>;
  }

  return <h3 className={componentClass}>{name}</h3>;
};

export default FoodName;
