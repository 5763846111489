import React from 'react';

import FAQItem from './FAQItem';

const EnglishFAQ: React.FunctionComponent = () => {
  return (
    <>
      <FAQItem
        question="Who can eat at UniCafe?"
        answer="UniCafe’s doors are open to all – we welcome everyone who enjoys good food!"
      />
      <FAQItem
        question="What does the lunch cost?"
        answer="See all our lunch prices <a href='https://menu.unicafe.fi/info/hinnasto'><u>here</u></a>."
      />
      <FAQItem
        question="What is included in the lunch?"
        answer="The lunch consists of a warm main dish and a side dish (for example potatoes or rice) or a salad portion. The lunch also includes bread and spread and a drink. For an extra fee, you can also enjoy dessert or add a coffee to your meal."
      />
      <FAQItem
        question="How do I get the student discount?"
        answer="You get the student discount by showing the cashier your student ID entitling you to the meal subsidy. A list of accepted student IDs is available on the Kela website. If you have just started your studies and do not have a student ID yet, you can temporarily get the discount by showing your certificate of student status."
      />
      <FAQItem
        question="Do staff members get a discount?"
        answer="Staff members are entitled to a discount. You get the discount by showing the cashier your ID entitling you to the staff discount."
      />
      <FAQItem
        question="I want to bring a group of students from my school to UniCafe for lunch. What do I do? Are school groups entitled to a discount?"
        answer="School groups are unfortunately not entitled to the meal subsidy. We do welcome such groups, however. We recommend you get in touch directly with the restaurant you wish to visit with your group and make arrangements with its staff."
      />
      <FAQItem
        question="Do you offer group discounts?"
        answer="Whenever possible, we can arrange for a meal opportunity for groups in connection with training or practice sessions, for example, as well as other events. We recommend you get in touch directly with the restaurant you wish to visit with your group and make arrangements with its staff."
      />
      <FAQItem
        question="Can students eat in the teachers’ restaurant?"
        answer="The teachers’ restaurant is meant primarily for staff use."
      />
      <FAQItem
        question="Why do you not have a certain popular meal option on offer every day?"
        answer="Variety is the spice of life! We know that some of our lunch options are beloved classics – and they are worth the wait."
      />
      <FAQItem
        question="Where can I send feedback?"
        answer="You can send feedback directly to the restaurant by email or by using our feedback system."
      />
      <FAQItem
        question="Why do you sometimes run out of certain foods?"
        answer="We only prepare as much food as we think will be needed in order to avoid silly amounts of food waste. That is why we may sometimes run out of a popular meal."
      />
      {/*<FAQItem
        question="How does Compensate work?"
        answer="To compensate for the carbon footprint of your meal, just tell the cashier that you wish to do so. We will transfer the full compensation fee to Compensate who, in turn, will direct the full amount to forestation projects."
  />*/}
      <FAQItem
        question="What is a Leftover lunch?"
        answer="Leftover lunches are meals that are put together from the day’s leftover foods and sold at a discounted price. You can buy a Leftover lunch after the restaurant’s lunch service has ended, either in a UniCafe box or your own lunch box."
      />
      <FAQItem
        question="Can I pay for cafeteria products at the lunch checkout?"
        answer="You can – except for those facilities where the cafeteria and the lunch restaurant are in different facilities."
      />
      <FAQItem
        question="Are UniCafe’s packaging materials, such as the takeaway cups, biodegradable?"
        answer="At the moment, only some of the packaging materials of takeaway products are biodegradable. We aim to be using biodegradable packaging materials for all UniCafe takeaway products by the end of 2020."
      />
    </>
  );
};

export default EnglishFAQ;
