import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './PrivacyPage.module.scss';

import { getAnalyticsPermission } from 'store/system/selectors';
import { setAnalyticsPermission } from 'store/system/actions';

import Page from 'components/Page';
import ScrollWrapper from 'components/ScrollWrapper';
import Container from 'components/Container';
import BackButton from 'components/BackButton';
import StandardContent from 'components/StandardContent';
import SwitchSetting from 'components/SwitchSetting';
import { useTranslation } from 'features/translator';

const PrivacyPage: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const analyticsPermission = useSelector(getAnalyticsPermission);
  const translation = useTranslation();

  return (
    <Page name="Yksityisyys" title={[translation.get('infoPage', 'title'), translation.get('privacyPage', 'title')]}>
      <div className={styles.component}>
        <ScrollWrapper>
          <Container>
            <div className={styles.back}>
              <BackButton></BackButton>
            </div>
            <div className={styles.header}>
              <h1 className={styles.title}>Yksityisyys</h1>
            </div>
            <div className={styles.content}>
              <div className={styles.cookies}>
                <StandardContent>
                  <h2 className="h3">Evästeasetukset</h2>
                  <p>
                    Käytämme sovelluksessa evästeitä taataksemme parhaan mahdollisen toimivuuden ja kehittääksemme
                    palvelua.
                  </p>
                </StandardContent>
                <div className={styles.switch}>
                  <SwitchSetting
                    title="Salli evästeet"
                    isActive={analyticsPermission}
                    clickHandler={() => dispatch(setAnalyticsPermission(!analyticsPermission))}
                  />
                </div>
              </div>
              <div className={styles.legal}>
                <StandardContent>
                  <h2 className="h3">Henkilötietojen käsittelyn tarkoitus ja peruste</h2>
                  <p>
                    UniCafen selainpohjainen sovellus käyttää Googlen evästeitä sekä puhelimen lokaatiotietoja. Näitä
                    käsitellään palvelun ja sivuston hoitamisessa, kehittämisessä ja seurannassa. Tietoja käytetään myös
                    asiakassuhteiden analysoinnissa, ryhmittelyssä ja raportoinnissa.
                  </p>
                  <p>
                    Tallennamme evästeiden avulla muun muassa seuraavan kaltaisia tietoja: yleiset demografiset tiedot
                    kuten ikä ja suuntaa antavat kiinnostuksen kohteet, sijainti, käyttöjärjestelmän kieli,
                    verkko-osoite josta käyttäjä on tullut verkkosivuille sekä tietoja sivun kävijämäärästä ja
                    suosituimmista sisällöistä.
                  </p>
                  <h3 className="h4">Henkilötietojen käsittelystä</h3>
                  <p>
                    Tietojen käsittelyssä noudatetaan tietosuojaperiaatteita. Tiedot kerätään tiettyä, nimenomaista
                    tarkoitusta varten, eikä niitä käsitellä myöhemmin näiden tarkoitusten kanssa yhteen sopimattomalla
                    tavalla. Henkilötietoja käsitellään lainmukaisesti, asianmukaisesti ja rekisteröidyn kannalta
                    läpinäkyvästi. Henkilötiedot ovat UniCafen WebAppia varten käyttötarkoituksen kannalta
                    asianmukaisia, olennaisia ja tarpeellisia.
                  </p>
                  <p>
                    Henkilötietojen käsittelyssä varmistetaan turvallisuus. Tiedot on suojattu luvattomalta ja
                    lainvastaiselta käsittelyltä. Rekisterinpitäjän varmistaa, että tiedot eivät häviä, tuhoudu tai
                    vahingoitu vahingossa. Suojaamisessa käytetään asianmukaisia teknisiä ja organisatorisia toimia.
                  </p>
                  <h3 className="h4">Evästeiden käytön estäminen selaimessa</h3>
                  <p>
                    Jos haluat estää evästeet, muuta selaimesi evästeasetuksia. Jos estät evästeiden käytön
                    selainohjelmassasi, laitettasi ei seurata, kun vierailet sivustossa. Huomaa kuitenkin, että
                    estämällä evästeiden käytön saatat estää myös joidenkin sivuston toimintojen käyttämisen.
                  </p>
                  <p>
                    1. Rekisterinpitäjä Ylva Palvelut Oy
                    <br />
                    Osoite: Kaivokatu 10 A, 3.krs (PL 1099), FI-00101 Helsinki
                    <br />
                    Y-tunnus: 0535669-8.
                  </p>
                </StandardContent>
              </div>
            </div>
          </Container>
        </ScrollWrapper>
      </div>
    </Page>
  );
};

export default PrivacyPage;
