import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './RestaurantsListedByFiltered.module.scss';

import { getRestaurantData } from 'store/data/selectors';
import { getFilteredRestaurants } from 'store/restaurants/selectors';
import { clearRestaurantFilters } from 'store/restaurants/actions';
import Restaurant, { IRestaurant } from './Restaurant';
import Container from 'components/Container';
import { useTranslation } from 'features/translator';

const RestaurantsListedByFiltered: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const restaurants: IRestaurant[] = useSelector(getRestaurantData);
  const filteredRestaurantIds = useSelector(getFilteredRestaurants);
  const filteredRestaurants = restaurants.filter((restaurant) => filteredRestaurantIds.includes(restaurant.id));
  const translation = useTranslation();

  /**
   * If restaurant ids change, or they get removed for example,
   * they might be left in storage as "ghosts".
   *
   * Let's make sure that our filters actually render some results,
   * if they don't, let's destroy the filtered array completely.
   */
  useEffect(() => {
    if (!filteredRestaurants.length) {
      dispatch(clearRestaurantFilters());
    }
  }, [dispatch, filteredRestaurants]);

  return (
    <div className={styles.component}>
      <Container>
        <div className={styles.header}>{translation.get('lunchPage', 'restaurantsListedByFilteredLabel')}</div>

        {filteredRestaurants.map((restaurant) => (
          <div className={styles.restaurant} key={restaurant.id}>
            <Restaurant {...restaurant}></Restaurant>
          </div>
        ))}
      </Container>
    </div>
  );
};

export default RestaurantsListedByFiltered;
