import React from 'react';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import styles from './DateButton.module.scss';

import { setSelectedDate, toggleDateModal } from 'store/date/actions';
import { useLanguage } from 'features/translator';
import { getLongDisplayDate } from 'utils/date';

interface Props {
  date: string;
  isSelected: boolean;
  isToday: boolean;
}

const DateButton: React.FunctionComponent<Props> = ({ date, isSelected, isToday }) => {
  const dispatch = useDispatch();
  const [activeLanguage] = useLanguage();
  const componentClass = classNames(styles.component, {
    [styles.isSelected]: isSelected,
  });

  const handleClick = () => {
    dispatch(setSelectedDate(date));

    // let's delay the modal close a bit to give eyes time to adjust
    setTimeout(() => {
      dispatch(toggleDateModal());
    }, 200);
  };

  return (
    <motion.button whileTap={{ scale: 0.9 }} tabIndex={0} className={componentClass} onClick={handleClick}>
      {isToday && <div className={styles.indicator} />}
      <div className={styles.date}>{getLongDisplayDate(date, activeLanguage)}</div>
    </motion.button>
  );
};

export default DateButton;
