import localForage from 'localforage';
import { createMigrate } from 'redux-persist';

import migrations from './migrations';
import { SET_ACTIVE_LANGUAGE } from './actions';
import { defaultLanguage, languages } from 'features/translator/config';
import { Language } from 'features/translator/types';

/**
 * Redux Persist configuration object. Add keys
 * you want to remain in storage into the whitelist array.
 */
export const translatorPersistConfig = {
  key: 'translator',
  storage: localForage,
  whitelist: ['activeLanguage'],
  migrate: createMigrate(migrations, { debug: process.env.NODE_ENV === 'development' }),
};

/**
 * Types
 */
interface IState {
  activeLanguage: Language;
  languages: Language[];
}

const initialState: IState = {
  activeLanguage: defaultLanguage,
  languages: Object.keys(languages) as Language[],
};

/**
 * Reducer
 */
export default function (state: IState = initialState, action: any) {
  switch (action.type) {
    case SET_ACTIVE_LANGUAGE: {
      const { activeLanguage } = action.payload;
      return {
        ...state,
        activeLanguage,
      };
    }
    default:
      return state;
  }
}
