import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './Menu.module.scss';

import Food, { IFood } from './Food';
import Announcement from './Announcement';
import { getAllowedDiets } from 'store/foods/selectors';
import { splitAnnouncementsAndFoods } from 'utils/foods';

export interface IMenu {
  date: string;
  data: IFood[];
  message: string | null;
}

const Menu: React.FunctionComponent<IMenu> = ({ data }) => {
  const [showUnsuitableFoods, setShowUnsuitableFoods] = useState(false);
  const allowedDiets = useSelector(getAllowedDiets);
  const { foods, announcements } = splitAnnouncementsAndFoods(data);
  const sortedFoods = sortFoodsBySuitability(foods, allowedDiets);
  const { suitableFoods, unsuitableFoods } = sortedFoods || {};
  const hasFoods = !!suitableFoods.length || !!unsuitableFoods.length;
  const hasAnnouncements = !!announcements.length;
  const hasUnsuitableFoods = !!unsuitableFoods.length;
  const unsuitableFoodsSuffix =
    unsuitableFoods.length > 1 ? 'ruokavalioosi sopimatonta annosta' : 'ruokavalioosi sopimaton annos';

  return (
    <div className={styles.component}>
      {hasFoods ? (
        <div className={styles.foods}>
          {suitableFoods.map((food, index) => (
            <div key={index} className={styles.food}>
              <Food data={food} hasUnsuitableDiet={false}></Food>
            </div>
          ))}

          {hasUnsuitableFoods && (
            <>
              {showUnsuitableFoods &&
                unsuitableFoods.map((food, index) => (
                  <div key={index} className={styles.food}>
                    <Food data={food} hasUnsuitableDiet={true}></Food>
                  </div>
                ))}
              <div className={styles.hiddenFoods}>
                <p className={styles.hiddenFoodsText}>
                  {unsuitableFoods.length} {unsuitableFoodsSuffix}
                </p>

                <button
                  className={styles.hiddenFoodsToggle}
                  onClick={() => setShowUnsuitableFoods(!showUnsuitableFoods)}>
                  {showUnsuitableFoods ? 'Piilota' : 'Näytä'}
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className={styles.error}>
          <p>Lounaslistaa ei löytynyt. Ravintola voi olla suljettu, tai emme ole vielä päivittäneet ruokalistaa.</p>
        </div>
      )}

      {hasAnnouncements && (
        <div className={styles.announcements}>
          {announcements.map((announcement, index) => (
            <Announcement key={index} name={announcement.name} />
          ))}
        </div>
      )}
    </div>
  );
};

interface ISortedFoods {
  suitableFoods: IFood[];
  unsuitableFoods: IFood[];
}

const sortFoodsBySuitability = (foods: IFood[], allowedDiets: string[]): ISortedFoods => {
  // If there are no allowed diets provided, mark all foods as suitable
  if (!allowedDiets.length) {
    return {
      suitableFoods: foods,
      unsuitableFoods: [],
    };
  }

  // Otherwise lets sort the foods into suitable and unsuitable
  return foods.reduce(
    (sortedFoods, food) => {
      const { meta } = food || {};
      const diets = meta[0] || [];

      const suitable = allowedDiets.reduce((acc, diet) => {
        // For people with Vegetarian (K) diet, Vegan (VE) meals are always suitable
        if (diet === 'K') {
          if (!diets.includes(diet) && !diets.includes('VE')) {
            return false;
          }
        }
        // For people with Low Lactose (VL) diets, No-Lactose (L) meals are always suitable
        else if (diet === 'VL') {
          if (!diets.includes(diet) && !diets.includes('L')) {
            return false;
          }
        }
        // For people with Milkless (M) diets, Vegan (VE) meals area always suitable
        else if (diet === 'M') {
          if (!diets.includes(diet) && !diets.includes('VE')) {
            return false;
          }
        } else if (!diets.includes(diet)) {
          return false;
        }

        return acc;
      }, true);

      if (suitable) {
        sortedFoods.suitableFoods.push(food);
      } else {
        sortedFoods.unsuitableFoods.push(food);
      }

      return sortedFoods;
    },
    {
      suitableFoods: [] as IFood[],
      unsuitableFoods: [] as IFood[],
    }
  );
};

export default Menu;
