import { languages, defaultLanguage } from './config';
import { Language } from './types';
import { capitalize } from '../../utils/helpers';
import { TranslationFile, AllergenTranslationFile, LabelTranslationFile } from './types';

export function createTranslator() {
  let language: Language = defaultLanguage;

  const setLanguage = (l: Language) => {
    language = l;
  };

  function get<T extends keyof TranslationFile, U extends keyof TranslationFile[T]>(
    namespace: T,
    id: U
  ): TranslationFile[T][U] {
    return languages[language].translation[namespace][id];
  }

  function allergens(allergens: string[]): string[] {
    const allergenTranslations = languages[language].allergens;

    try {
      const translatedAllergens = translateAllergens(allergens, allergenTranslations);
      return translatedAllergens;
    } catch {
      return allergens;
    }
  }

  function translateAllergens(allergens: string[], translation: AllergenTranslationFile | null): string[] {
    validateTranslationFile(translation);

    return allergens.map((allergen: string) => {
      if (isIndexOfObject(allergen, translation)) {
        return translation[allergen];
      }

      return allergen;
    });
  }

  function label(label: string): string {
    const labelTranslations = languages[language].labels;
    const lowerCaseLabel = label.toLowerCase();

    try {
      const translatedLabel = translateLabel(lowerCaseLabel, labelTranslations);
      return capitalize(translatedLabel);
    } catch {
      return label;
    }
  }

  function translateLabel(label: string, translation: LabelTranslationFile | null): string {
    validateTranslationFile(translation);

    if (isIndexOfObject(label, translation)) {
      return translation[label];
    }

    return label;
  }

  function validateTranslationFile<T>(translation: T): asserts translation is NonNullable<T> {
    if (translation === null) {
      throw new Error('No translation file found.');
    }
  }

  function isIndexOfObject(key: string, obj: object): key is keyof typeof obj {
    return key in obj;
  }

  return {
    setLanguage(l: Language) {
      setLanguage(l);
      return this;
    },
    get,
    allergens,
    label,
  };
}
