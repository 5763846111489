import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './Food.module.scss';

import analytics from 'utils/analytics';
import FoodCategory, { getCategoryLabel } from './FoodCategory';
import FoodName from './FoodName';
import FoodDiets from './FoodDiets';
import { openFoodDetails } from 'store/restaurants/actions';
import { useTranslation } from 'features/translator';

export interface IFood {
  name: string;
  nutrition: string;
  sku: string;
  ingredients: string;
  price: {
    name: string;
    value: {
      student: string;
      contract: string;
      graduate: string;
      student_hyy: string;
      normal: string;
      graduate_hyy: string;
    };
  };
  meta: string[][];
}

interface FoodProps {
  data: IFood;
  hasUnsuitableDiet: boolean;
}

const Food: React.FunctionComponent<FoodProps> = ({ data, hasUnsuitableDiet }) => {
  const translation = useTranslation();
  const { name, meta } = data;
  const dispatch = useDispatch();
  const diets = meta[0] || [];
  const allergens = meta[1] || [];
  const labels = meta[2] || [];

  const hasAllergens = !!allergens.length;
  const hasLabels = !!labels.length;

  const handleClick = () => {
    analytics.openFoodDetails(name, getCategoryLabel(data));
    dispatch(openFoodDetails(data));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (event.key === 'Enter') {
      analytics.openFoodDetails(name, getCategoryLabel(data));
      dispatch(openFoodDetails(data));
    }
  };

  return (
    <div className={styles.component} role="button" tabIndex={0} onClick={handleClick} onKeyDown={handleKeyDown}>
      <div className={styles.title}>
        <FoodName text={name}></FoodName>
        <div className={styles.extra}>
          {hasLabels && labels[0] !== 'Ilmastovalinta' && <div className={styles.label}>{translation.label(labels[0])}</div>}
          {hasAllergens && <div className={styles.allergens}>{translation.allergens(allergens).join(', ')}</div>}
        </div>
        {hasLabels && labels[0] === 'Ilmastovalinta' && <div className={styles.climateChoiceLabel}>{translation.label(labels[0])}</div>}
      </div>
      <div className={styles.meta}>
        <FoodCategory data={data}></FoodCategory>
        <FoodDiets diets={diets} hasUnsuitables={hasUnsuitableDiet}></FoodDiets>
      </div>
    </div>
  );
};

export default Food;
