import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

export const getAllowedDiets = (state: RootState): string[] => state.foods.allowedDiets;

/**
 * Memoized selectors
 * https://react-redux.js.org/api/hooks#using-memoizing-selectors
 */
export const getAllowedDietsString = createSelector(getAllowedDiets, (data) => {
  return data.join('-');
});
