import React from 'react';
import { BrowserRouter as BaseRouter } from 'react-router-dom';
import { MotionRouterProvider } from './MotionRouterProvider';

export const MotionRouter: React.FunctionComponent = ({ children }) => {
  return (
    <BaseRouter>
      <MotionRouterProvider>{children}</MotionRouterProvider>
    </BaseRouter>
  );
};
