import React from 'react';
import styles from './FAQPage.module.scss';

import Page from 'components/Page';
import ScrollWrapper from 'components/ScrollWrapper';
import Container from 'components/Container';
import BackButton from 'components/BackButton';
import { useTranslation, useLanguage, Language } from 'features/translator';
import FinnishFAQ from './FinnishFAQ';
import EnglishFAQ from './EnglishFAQ';
import SwedishFAQ from './SwedishFAQ';

const FAQPage: React.FunctionComponent = () => {
  const translation = useTranslation();
  const [activeLanguage] = useLanguage();

  return (
    <Page name="Usein kysyttyä" title={[translation.get('infoPage', 'title'), translation.get('FAQPage', 'title')]}>
      <div className={styles.component}>
        <ScrollWrapper>
          <Container>
            <div className={styles.back}>
              <BackButton></BackButton>
            </div>
            <div className={styles.header}>
              <h1 className={styles.title}>{translation.get('FAQPage', 'title')}</h1>
            </div>
            <div className={styles.content}>{getContent(activeLanguage)}</div>
          </Container>
        </ScrollWrapper>
      </div>
    </Page>
  );
};

const getContent = (language: Language) => {
  switch (language) {
    case 'fi': {
      return <FinnishFAQ />;
    }
    case 'sv': {
      return <SwedishFAQ />;
    }
    case 'en': {
      return <EnglishFAQ />;
    }
    default: {
      return null;
    }
  }
};

export default FAQPage;
