import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { MotionRouterContext } from './MotionRouterContext';
import { useRoutes } from './use-routes';
import { useIsActive } from './use-is-active';

interface Props {
  to: string;
  className?: string;
}

export const MotionNavLink: React.FunctionComponent<Props> = ({ to, className, children }) => {
  const { currentRoute } = useRoutes();
  const context = useContext(MotionRouterContext);
  const isActive = useIsActive(to);

  return (
    <NavLink
      to={{
        pathname: to,
        state: {
          oldRoute: currentRoute,
        },
      }}
      isActive={() => isActive}
      className={className}
      style={{ pointerEvents: context?.isAnimating ? 'none' : 'auto' }}>
      {children}
    </NavLink>
  );
};
