import React from 'react';
import classNames from 'classnames';
import { motion, useAnimation } from 'framer-motion';

import styles from './MenuButton.module.scss';
import { ReactComponent as ChevronRight } from './assets/chevron-right.svg';

interface IMenuButton {
  icon?: React.FunctionComponent | null;
  text: string;
  theme?: 'white' | 'green';
}

const MenuButton: React.FunctionComponent<IMenuButton> = ({ icon = null, text, theme = 'white' }) => {
  const componentClass = classNames(styles.component, styles[theme], {
    [styles.noIcon]: !icon,
  });

  const controls = useAnimation();

  const onTap = () => {
    controls.start({
      scale: [null, 0.9, 1],
      transition: { duration: 0.25 },
    });
  };

  return (
    <motion.div onTap={onTap} animate={controls} className={componentClass}>
      {icon && <div className={styles.icon}>{React.createElement(icon)}</div>}
      <div className={styles.text}>{text}</div>
      <div className={styles.arrow}>
        <ChevronRight />
      </div>
    </motion.div>
  );
};

export default MenuButton;
