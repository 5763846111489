import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import styles from './Modal.module.scss';

import CloseButton from './CloseButton';
import ScrollWrapper from 'components/ScrollWrapper';
import { useTranslation } from 'features/translator';

const slideUp = {
  initial: { y: '100%' },
  open: { y: 0 },
  closed: { y: '100%' },
};

const slideDown = {
  initial: { y: '-100%' },
  open: { y: 0 },
  closed: { y: '-100%' },
};

const slideRight = {
  initial: { x: '-100%' },
  open: { x: 0 },
  closed: { x: '-100%' },
};

const slideLeft = {
  initial: { x: '100%' },
  open: { x: 0 },
  closed: { x: '100%' },
};

interface Props {
  direction?: 'up' | 'down' | 'right' | 'left';
  closeHandler: (event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent) => void;
}

const Modal: React.FunctionComponent<Props> = ({ direction = 'up', children, closeHandler }) => {
  const translation = useTranslation();

  const handleCloseClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    closeHandler(event);
  };

  useEffect(() => {
    const keyboardCloseListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeHandler(event);
      }
    };

    document.addEventListener('keydown', keyboardCloseListener);

    return () => {
      document.removeEventListener('keydown', keyboardCloseListener);
    };
  }, [closeHandler]);

  const animations = {
    up: slideUp,
    down: slideDown,
    right: slideRight,
    left: slideLeft,
  };

  return (
    <motion.div
      initial={'initial'}
      animate={'open'}
      exit={'closed'}
      variants={animations[direction]}
      transition={{ type: 'spring', mass: 0.5, stiffness: 300, damping: 25 }}
      className={styles.component}>
      <ScrollWrapper>
        <div className={styles.container}>
          <div className={styles.content}>{children}</div>
          <motion.button
            whileTap={{ scale: 0.9 }}
            whileHover={{ scale: 1.05 }}
            className={styles.closeTop}
            tabIndex={0}
            onClick={handleCloseClick}>
            {translation.get('general', 'close')}
          </motion.button>
          <div className={styles.closeBottom}>
            <CloseButton closeHandler={handleCloseClick}></CloseButton>
          </div>
        </div>
      </ScrollWrapper>
    </motion.div>
  );
};

export default Modal;
