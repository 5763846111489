import React from 'react';
import { useSelector } from 'react-redux';

import { getRestaurantData } from 'store/data/selectors';
import { IRestaurant } from './Restaurant';

const RestaurantsListedByDistance: React.FunctionComponent = () => {
  const restaurants: IRestaurant[] = useSelector(getRestaurantData);

  if (!restaurants) {
    return null;
  }

  return <h1>Listed by distance</h1>;
};

export default RestaurantsListedByDistance;
