import { IDisplayMode } from 'pages/Lunch/RestaurantList';

export const TOGGLE_COLLAPSE: string = '[restaurants] Toggle Restaurant Collapse';
export const TOGGLE_FILTER: string = '[restaurants] Toggle Restaurant Filter';
export const ADD_FILTERS: string = '[restaurants] Add Restaurant Filters';
export const REMOVE_FILTERS: string = '[restaurants] Remove Restaurant Filters';
export const CLEAR_FILTERS: string = '[restaurants] Clear Restaurant Filters';
export const OPEN_DETAILS: string = '[restaurants] Open Restaurant Details';
export const CLOSE_DETAILS: string = '[restaurants] Close Restaurant Details';
export const OPEN_FOOD_DETAILS: string = '[restaurants] Open Food Details';
export const CLOSE_FOOD_DETAILS: string = '[restaurants] Close Food Details';
export const SET_DISPLAY_MODE: string = '[restaurants] Set Display Mode';

export const toggleRestaurantCollapse = (id: number) => ({
  type: TOGGLE_COLLAPSE,
  payload: id,
});

export const toggleRestaurantFilter = (id: number) => ({
  type: TOGGLE_FILTER,
  payload: id,
});

export const addRestaurantFilters = (ids: number[]) => ({
  type: ADD_FILTERS,
  payload: ids,
});

export const removeRestaurantFilters = (ids: number[]) => ({
  type: REMOVE_FILTERS,
  payload: ids,
});

export const clearRestaurantFilters = () => ({
  type: CLEAR_FILTERS,
});

export const openRestaurantDetails = (restaurant: any) => ({
  type: OPEN_DETAILS,
  payload: restaurant,
});

export const closeRestaurantDetails = () => ({
  type: CLOSE_DETAILS,
});

export const openFoodDetails = (food: any) => ({
  type: OPEN_FOOD_DETAILS,
  payload: food,
});

export const closeFoodDetails = () => ({
  type: CLOSE_FOOD_DETAILS,
});

export const setDisplayMode = (displayMode: IDisplayMode) => ({
  type: SET_DISPLAY_MODE,
  payload: displayMode,
});
