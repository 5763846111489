import React, { useState, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import styles from './RestaurantTimes.module.scss';

import { IVisitingHours } from 'pages/Lunch/Restaurant';
import OpeningTimes from 'components/OpeningTimes';
import analytics from 'utils/analytics';
import { useTranslation } from 'features/translator';

interface Props {
  title: string;
  visitingHours?: IVisitingHours;
}

const RestaurantTimes: React.FunctionComponent<Props> = ({ title, visitingHours }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const translation = useTranslation();
  const isClosed = useMemo(() => {
    if (typeof visitingHours === 'undefined' || visitingHours['business'] === false) {
      return true;
    }

    // Let's check if there's a "exceptionUndefinedTime" key set to true, which means the restaurant is closed
    let exceptionUndefinedTime = visitingHours['business'].items.filter((item) => item.exceptionUndefinedTime);

    if (exceptionUndefinedTime.length) {
      return true;
    }

    return false;
  }, [visitingHours]);

  if (isClosed) {
    return (
      <div className={`${styles.component} ${styles.isClosed}`} aria-expanded={isExpanded}>
        <div className={styles.title}>{title}</div>
        <span className={styles.message}>{translation.get('restaurants', 'closed')}</span>
      </div>
    );
  }

  const handleClick = () => {
    if (!isExpanded) {
      analytics.openOpeningHours(title);
    }

    setIsExpanded(!isExpanded);
  };

  if (!visitingHours) {
    return null;
  }

  return (
    <div className={styles.component} aria-expanded={isExpanded}>
      <button className={styles.title} onClick={handleClick}>
        {title}
      </button>

      <AnimatePresence initial={false}>
        {isExpanded && (
          <motion.div
            initial={'collapsed'}
            animate={'expanded'}
            exit={'collapsed'}
            variants={{
              expanded: { height: 'auto', transition: { type: 'spring', stiffness: 200, mass: 0.25, damping: 20 } },
              collapsed: { height: 0, transition: { type: 'spring', stiffness: 200, mass: 0.1, damping: 10 } },
            }}
            className={styles.wrapper}>
            <div className={styles.text}>
              <OpeningTimes visitingHours={visitingHours} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default RestaurantTimes;
