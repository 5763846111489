import React from 'react';
import styles from './RestaurantsPage.module.scss';

import Page from 'components/Page';
import Container from 'components/Container';
import ScrollWrapper from 'components/ScrollWrapper';
import StandardContent from 'components/StandardContent';
import BackButton from 'components/BackButton';
import Filters from './Filters';
import { useTranslation } from 'features/translator';

const RestaurantsPage: React.FunctionComponent = () => {
  const translation = useTranslation();

  return (
    <Page
      name="Ravintolat"
      title={[translation.get('settingsPage', 'title'), translation.get('restaurantsPage', 'title')]}>
      <div className={styles.component}>
        <ScrollWrapper>
          <Container>
            <div className={styles.back}>
              <BackButton></BackButton>
            </div>
            <div className={styles.header}>
              <StandardContent>
                <h1>{translation.get('restaurantsPage', 'title')}</h1>
                <p>{translation.get('restaurantsPage', 'description')}</p>
              </StandardContent>
            </div>
            <div className={styles.filters}>
              <Filters></Filters>
            </div>
          </Container>
        </ScrollWrapper>
      </div>
    </Page>
  );
};

export default RestaurantsPage;
