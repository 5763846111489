import { Animation } from './types';

export const slideFromRight = (): Animation => ({
  initial: { x: '100%', opacity: 1, zIndex: 2 },
  enter: { x: '0%', opacity: 1, zIndex: 2 },
  exit: { x: '-75%', opacity: 0.9, zIndex: 1 },
});

export const slideFromLeft = (): Animation => ({
  initial: { x: '-100%', opacity: 1, zIndex: 2 },
  enter: { x: '0%', opacity: 1, zIndex: 2 },
  exit: { x: '75%', opacity: 0.9, zIndex: 1 },
});

export const zoomOut = (): Animation => ({
  initial: { x: '100%', scale: 1.2, zIndex: 2 },
  enter: { x: '0%', scale: 1, zIndex: 2 },
  exit: { x: '-20%', scale: 0.8, opacity: 0.5, zIndex: 1 },
});

export const zoomIn = (): Animation => ({
  initial: { x: '-20%', scale: 0.8, opacity: 0.5, zIndex: 1 },
  enter: { x: '0%', scale: 1, opacity: 1, zIndex: 1 },
  exit: { x: '100%', scale: 1.2, opacity: 1, zIndex: 2, originX: 0 },
});

export const reveal = (): Animation => ({
  initial: { scale: 0, opacity: 1, borderRadius: '100%', originX: 0.5, originY: 1, zIndex: 2 },
  enter: { scale: 1, opacity: 1, borderRadius: '0%', originX: 0.5, originY: 1, zIndex: 2 },
  exit: { scale: 0.9, opacity: 0.5, originX: 0.5, originY: 0.5, zIndex: 1 },
});
