import React from 'react';
import { useSelector } from 'react-redux';
import { MotionLink } from 'features/motion-router';

import { getDisplayMode } from 'store/restaurants/selectors';
import { useTranslation } from 'features/translator';
import Button from 'components/Button';
import { ReactComponent as Funnel } from './assets/sliders.svg';

const FiltersLink: React.FunctionComponent = () => {
  const displayMode = useSelector(getDisplayMode);
  const translation = useTranslation();

  return (
    <MotionLink to="/asetukset/ravintolat">
      <Button
        text={translation.get('lunchPage', 'filtersLink')}
        icon={Funnel}
        iconOffsetY={1}
        iconPlacement={'before'}
        border={displayMode === 'filtered' ? false : true}
        theme={displayMode === 'filtered' ? 'greenBright' : 'white'}></Button>
    </MotionLink>
  );
};

export default FiltersLink;
