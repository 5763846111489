import localForage from 'localforage';
import { createMigrate } from 'redux-persist';

import migrations from './migrations';
import {
  LOAD_DATA_REQUEST,
  LOAD_DATA_SKIPPED,
  LOAD_DATA_FAILED,
  LOAD_DATA_COMPLETE,
  UPDATE_RESTAURANTS,
  UPDATE_DATES,
} from './actions';
import { IRestaurant } from 'pages/Lunch/Restaurant';

/**
 * Redux Persist configuration object. Add keys
 * you want to remain in storage into the whitelist array.
 */
export const dataPersistConfig = {
  key: 'data',
  storage: localForage,
  version: 1,
  whitelist: ['lastUpdate', 'restaurants', 'dates'],
  migrate: createMigrate(migrations, { debug: process.env.NODE_ENV === 'development' }),
};

/**
 * Types
 */
interface IState {
  lastUpdate?: Date | string;
  isLoading: boolean;
  error: boolean;
  restaurants: IRestaurant[];
  dates: string[];
}

const initialState: IState = {
  error: false,
  isLoading: false,
  restaurants: [],
  dates: [],
};

/**
 * Reducer
 */
export default function (state: IState = initialState, action: any) {
  switch (action.type) {
    case LOAD_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    }
    case LOAD_DATA_SKIPPED: {
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    }
    case LOAD_DATA_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    }
    case LOAD_DATA_COMPLETE: {
      return {
        ...state,
        lastUpdate: new Date(),
        isLoading: false,
        error: false,
      };
    }
    case UPDATE_RESTAURANTS: {
      return {
        ...state,
        restaurants: action.payload,
      };
    }
    case UPDATE_DATES: {
      return {
        ...state,
        dates: action.payload,
      };
    }
    default:
      return state;
  }
}
