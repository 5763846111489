declare global {
  interface Window {
    dataLayer: any[];
  }
}

let currentAnalyticsPermission: boolean = false;

/**
 * Set analytics allowed boolean
 */
export const setAnalyticsPermission = (analyticsPermission: boolean) => {
  currentAnalyticsPermission = analyticsPermission;
};

/**
 * Check if we are allowed to use analytics or not
 */
export const hasAnalyticsPermission = (): boolean => {
  return currentAnalyticsPermission;
};

/**
 * Helper function for triggering pageview events
 */
const pageView = (url: string, title: string, diets: string, restaurants: string) => {
  return push({
    event: 'virtualPageview',
    virtualPageURL: url,
    virtualPageTitle: title,
    diets,
    restaurants,
  });
};

/**
 * Helper function for opening times accordion toggles
 */
const openOpeningHours = (restaurant: string) => {
  return push({
    event: 'openingHours',
    restaurant,
  });
};

/**
 * Helper function for FAQ accordion toggles
 */
const openFAQ = (question: string) => {
  return push({
    event: 'FAQ',
    question,
  });
};

/**
 * Helper function for food infirmation opening
 */
const openFoodDetails = (name: string, type: string) => {
  return push({
    event: 'dish',
    dishName: name,
    dishType: type,
  });
};

/**
 * Generic helper function for pushing to data layer
 */
const push = (data: any) => {
  if (!hasAnalyticsPermission()) {
    return false;
  }

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(data);
};

export default {
  pageView,
  openOpeningHours,
  openFAQ,
  openFoodDetails,
};
