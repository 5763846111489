import React from 'react';

const EnglishContent = () => {
  return (
    <>
      <p>
        Waste food, organic, Fair Trade, vegan food – we at UniCafe are known for enabling smart and sustainable
        choices. We prepare food that is friendly to your wallet as well as the environment, and that you can enjoy
        easily and with a good conscience. We are showing the way for future student meals, and proud of it.
      </p>
      <p>
        The secret to our success is to cook food that is good for everyone and everything. Our philosophy is that each
        meal must be as responsible, local and healthy as possible while also tasting good. Ethics, taste and price are
        not mutually exclusive if you know what you are doing. Whether pulled oats or crickets, we keep curiously
        searching for new ingredients with which to delight our customers. On our menu, you can find modern classics as
        well as some surprising world flavours.
      </p>
      <p>
        Our doors are open to everyone in the city. So, gather your best mates at a table and take some me-time in the
        middle of a busy day. After all, the world cannot be saved on an empty stomach.
      </p>
      <p>
        <a href="https://unicafe.fi/en/food/" target="_blank" rel="noopener noreferrer">
          Read more on our website
        </a>
      </p>
    </>
  );
};

export default EnglishContent;
