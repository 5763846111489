import React from 'react';

import { IVisitingHours } from 'pages/Lunch/Restaurant';
import OpeningTime from './OpeningTime';

interface Props {
  visitingHours: IVisitingHours;
}

const OpeningTimes: React.FunctionComponent<Props> = ({ visitingHours }) => {
  return (
    <>
      <OpeningTime type="business" items={visitingHours['business']} />
      <OpeningTime type="lunch" items={visitingHours['lounas']} />
      <OpeningTime type="breakfast" items={visitingHours['breakfast']} />
      <OpeningTime type="bistro" items={visitingHours['bistro']} />
    </>
  );
};

export default OpeningTimes;
