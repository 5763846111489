import { IFood } from '../pages/Lunch/Food';

export const checkIsAnnouncement = ({ price }: IFood): boolean => {
  const { name } = price || {};

  return name.toLowerCase().includes('tiedoitus');
};

interface IFoodsAndAnnouncements {
  foods: IFood[];
  announcements: IFood[];
}

export const splitAnnouncementsAndFoods = (data: IFood[]): IFoodsAndAnnouncements => {
  const obj = {
    foods: [] as IFood[],
    announcements: [] as IFood[],
  };

  data.forEach((food) => {
    const isAnnouncement = checkIsAnnouncement(food);

    if (isAnnouncement) {
      obj.announcements.push(food);
    } else {
      obj.foods.push(food);
    }
  });

  return obj;
};
