import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadData } from 'store/data/actions';
import { updateToday } from 'store/date/actions';
import { useLanguage } from 'features/translator';

const DataLoader: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const [activeLanguage] = useLanguage();
  const [lastLoadedLanguage, setLastLoadedLanguage] = useState(activeLanguage);

  useEffect(() => {
    dispatch(updateToday());
    dispatch(loadData());
  }, [dispatch]);

  useEffect(() => {
    if (activeLanguage !== lastLoadedLanguage) {
      dispatch(loadData(false));
      setLastLoadedLanguage(activeLanguage);
    }
  }, [dispatch, activeLanguage, lastLoadedLanguage]);

  useEffect(() => {
    const onVisibilityChange = function () {
      if (document.visibilityState && document.visibilityState === 'visible') {
        dispatch(updateToday());
        dispatch(loadData());
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [dispatch]);

  return null;
};

export default DataLoader;
