import React, { useContext, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Switch, useLocation } from 'react-router-dom';
import { MotionRouterContext } from './MotionRouterContext';

export const MotionSwitch: React.FunctionComponent = ({ children }) => {
  const location = useLocation();
  const context = useContext(MotionRouterContext);
  const { setRoutePaths } = context || {};

  useEffect(() => {
    if (setRoutePaths) {
      setRoutePaths(extractRoutePaths(children));
    }
  }, [children, setRoutePaths]);

  return (
    <AnimatePresence initial={false} custom={{ newRoute: location.pathname }}>
      <Switch location={location} key={location.pathname}>
        {children}
      </Switch>
    </AnimatePresence>
  );
};

const extractRoutePaths = (children: React.ReactNode) => {
  const nodeArray = React.Children.toArray(children);
  let paths = [] as string[];

  nodeArray.forEach((each: any) => {
    if (each?.props?.path) {
      paths.push(each.props.path);
    }
  });

  return paths;
};
