import React from 'react';

import FAQItem from './FAQItem';

const SwedishFAQ: React.FunctionComponent = () => {
  return (
    <>
      <FAQItem
        question="Vem kan äta på UniCafe?"
        answer="UniCafe är öppet för alla – vi välkomnar alla som gillar god mat!"
      />
      <FAQItem
        question="Vad kostar lunchen?"
        answer="Alla våra lunchpriser ser du <a href='https://menu.unicafe.fi/info/hinnasto'><u>här</u></a>."
      />
      <FAQItem
        question="Vad ingår i lunchen?"
        answer="Lunchen består av en varm huvudrätt med tillägg (t.ex. potatis eller ris) eller en salladsportion. Dessutom ingår bröd med bredbart pålägg samt dryck. Mot extra betalning får du också efterrätt eller kaffe på lunchen."
      />
      <FAQItem
        question="Hur får jag studentrabatt?"
        answer="Studentrabatt får du genom att i kassan visa upp ett studentkort som ger rätt till måltidsstöd. En lista på sådana kort finns på FPA:s sida. Om du nyligen har inlett studierna och ännu inte har något studentkort kan du tillfälligt få rabatt genom att visa upp ditt närvarointyg."
      />
      <FAQItem
        question="Får personalen rabatt?"
        answer="Personalen har rätt till rabatt. Du får rabatten genom att i kassan visa upp ett kort som ger rätt till personalrabatt."
      />
      <FAQItem
        question="Jag vill ta en grupp skolelever på lunch till UniCafe. Hur ska jag göra? Har skolelever rätt till rabatt?"
        answer="Skolelever har tyvärr inte rätt till rabatt, men grupper är välkomna till oss. Vi rekommenderar att du tar kontakt med den restaurang där ni vill äta och kommer överens om arrangemangen med personalen där."
      />
      <FAQItem
        question="Ger ni grupprabatt?"
        answer="I mån av möjlighet tar vi gärna emot grupper, till exempel i samband med kurser, praktikperioder eller andra evenemang. Vi rekommenderar att du tar kontakt med den restaurang där ni vill äta och kommer överens om arrangemangen med personalen där."
      />
      <FAQItem
        question="Kan studerande äta i personalens matsal?"
        answer="Lärarnas restaurang är huvudsakligen avsedd för personalen."
      />
      <FAQItem
        question="Varför serverar ni inte någon viss populär rätt varje dag?"
        answer="Omväxling förnöjer! Vi vet att en del av våra lunchalternativ är älskade klassiker – den som väntar på något gott..."
      />
      <FAQItem
        question="Till vem kan jag skicka feedback?"
        answer="Du kan skicka feedback direkt till restaurangen, antingen per e-post eller genom vårt feedbacksystem."
      />
      <FAQItem
        question="Varför tar maten slut ibland?"
        answer="Vi tillreder bara så mycket som vi tror att behövs, så det inte ska uppstå så fruktansvärt mycket matsvinn. Därför kan vissa populära rätter ibland ta slut under dagen."
      />
      {/*<FAQItem
        question="Hur fungerar Compensate?"
        answer="Om du vill kompensera för måltidens koldioxidutsläpp ska du säga till i kassan då du betalar. Vi överför avgiften oavkortat till Compensate, som i sin tur överför summan oavkortat till olika beskogningsprojekt."
  />*/}
      <FAQItem
        question="Vad är en Leftover-lunch?"
        answer="Leftover-luncher består av måltider som har blivit över under dagen och som säljs till rabatterat pris. Du kan köpa en Leftover-lunch då lunchserveringen har slutat för dagen och ta med den hem i en UniCafe-låda eller i en egen matlåda."
      />
      <FAQItem
        question="Kan jag betala för caféprodukter i lunchkassan?"
        answer="Det kan du, utom i de fall då caféet och lunchrestaurangen befinner sig i olika lokaler."
      />
      <FAQItem
        question="Använder UniCafe biologiskt nedbrytbara förpackningsmaterial, t.ex. i take away-kopparna?"
        answer="För tillfället är bara en del av förpackningsmaterialet biologiskt nedbrytbart. Vår målsättning är att alla UniCafes take-away-produkter ska få biologiskt nedbrytbara förpackningar under 2020."
      />
    </>
  );
};

export default SwedishFAQ;
