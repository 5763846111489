import { Language } from 'features/translator';

export function getApiUrl(path: string, language: Language): string {
  const baseUrl = 'https://unicafe.fi/wp-json/swiss/v1';

  return `${baseUrl}/${path}?lang=${language}`;
}

export function normalizeRestaurantData(data: any): any {
  const normalizedData = data.map(replaceWordPressIDWithMessiID).map(normalizeMenuDates);
  return normalizedData;
}

const replaceWordPressIDWithMessiID = (restaurant: any) => {
  const { menuData } = restaurant || {};
  const { id } = menuData || false;

  if (id) {
    restaurant.id = id;
  }

  return restaurant;
};

const normalizeMenuDates = (restaurant: any) => {
  const { menuData } = restaurant || {};
  const { menus } = menuData || false;

  if (menus) {
    restaurant.menuData.menus = menus.map((menu: any) => {
      const { date } = menu || {};
      const [dayName, numericDate] = date.split(' ');

      if (dayName && numericDate) {
        const normalizedDate = `${normalizeDayName(dayName)} ${numericDate}`;
        menu.date = normalizedDate;
      }

      return menu;
    });
  }

  return restaurant;
};

const normalizeDayName = (date: any) => {
  switch (date) {
    case 'Ma':
    case 'Mån':
    case 'Mon': {
      return 'Monday';
    }
    case 'Ti':
    case 'Tis':
    case 'Tue': {
      return 'Tuesday';
    }
    case 'Ke':
    case 'Ons':
    case 'Wed': {
      return 'Wednesday';
    }
    case 'To':
    case 'Tors':
    case 'Thu': {
      return 'Thursday';
    }
    case 'Pe':
    case 'Fre':
    case 'Fri': {
      return 'Friday';
    }
    case 'La':
    case 'Lör':
    case 'Sat': {
      return 'Saturday';
    }
    case 'Su':
    case 'Sön':
    case 'Sun': {
      return 'Sunday';
    }
    default: {
      return 'Unknown';
    }
  }
};
