import React from 'react';

import styles from './SwitchSetting.module.scss';

import SwitchButton from 'components/SwitchButton';

interface ISwitchSetting {
  title: string;
  isActive: boolean;
  clickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const SwitchSetting: React.FunctionComponent<ISwitchSetting> = ({ title, isActive, clickHandler }) => {
  return (
    <div className={styles.component}>
      <h3 className={`h4 ${styles.title}`}>{title}</h3>
      <SwitchButton isActive={isActive} clickHandler={clickHandler} />
    </div>
  );
};

export default SwitchSetting;
