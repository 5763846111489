import React from 'react';
import styles from './FoodCategory.module.scss';

import { IFood } from './Food';
import { useTranslation } from 'features/translator';

export interface ICategory {
  id: string;
  label: string;
}

export const getFoodCategory = ({ meta, price }: IFood): ICategory => {
  const diets = meta[0] || [];
  const allergens = meta[1] || [];
  const extras = meta[2] || [];

  let category = { id: '', label: '' };

  // look for specific diets
  if (diets.find((diet) => diet === 'VE')) {
    category = { id: 'vegan', label: 'Vegaani' };
  } else if (diets.find((diet) => diet === 'K')) {
    category = { id: 'vegetarian', label: 'Kasvis' };
  }

  // check if the food is a fish dish
  if (
    allergens.find((allergen) => allergen.toLowerCase().includes('kala')) ||
    extras.find((extra) => extra.toLowerCase().includes('kala'))
  ) {
    category = { id: 'fish', label: 'Kala' };
  }

  // check if the food is a dessert
  if (price.name === 'Makeasti') {
    category = { id: 'dessert', label: 'Makeasti' };
  }

  // check if the food is a special of the day
  if (price.name?.toLowerCase().includes('erikoinen')) {
    category = { id: 'special', label: 'Päivän erikoinen' };
  }

  // check if the food is just an announcement message
  if (price.name?.toLowerCase().includes('tiedoitus')) {
    category = { id: 'tiedoitus', label: 'Tiedoitus' };
  }

  // finally, fall back to a generic category
  if (!category.id) {
    category = { id: 'lunch', label: 'Päivän lounas' };
  }

  return category;
};

export const getCategoryLabel = (data: IFood) => {
  return getFoodCategory(data).label;
};

export interface IFoodCategory {
  data: IFood;
  size?: 'small' | 'large';
}

const FoodCategory: React.FunctionComponent<IFoodCategory> = ({ data, size = 'small' }) => {
  const translation = useTranslation();
  const category = getFoodCategory(data);

  if (!category.id) {
    return null;
  }

  const translatedLabel = (function (id: string) {
    switch (id) {
      case 'vegan': {
        return translation.get('foods', 'vegan');
      }
      case 'vegetarian': {
        return translation.get('foods', 'vegetarian');
      }
      case 'fish': {
        return translation.get('foods', 'fish');
      }
      case 'dessert': {
        return translation.get('foods', 'dessert');
      }
      case 'special': {
        return translation.get('foods', 'special');
      }
      case 'tiedoitus': {
        return translation.get('foods', 'announcement');
      }
      case 'lunch': {
        return translation.get('foods', 'lunch');
      }
      default: {
        return category.label;
      }
    }
  })(category.id);

  return <h4 className={`${styles.component} ${styles[category.id]} ${styles[size]}`}>{translatedLabel}</h4>;
};

export default FoodCategory;
