import React from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import styles from './Button.module.scss';

import ButtonIcon, { TIcon } from './ButtonIcon';

interface IButton {
  text: string;
  url?: string;
  target?: '_self' | '_blank';
  clickHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  theme?: 'white' | 'green' | 'greenBright' | 'red';
  size?: 'small' | 'medium' | 'large';
  border?: boolean;
  icon?: TIcon;
  iconPlacement?: 'before' | 'after';
  iconOffsetX?: number;
  iconOffsetY?: number;
}

const Button: React.FunctionComponent<IButton> = ({
  text,
  url,
  target = '_self',
  clickHandler,
  theme = 'white',
  size = 'medium',
  border = false,
  icon = null,
  iconPlacement = 'after',
  iconOffsetX = 0,
  iconOffsetY = 0,
}) => {
  const hasIcon = !!icon;
  const componentClass = classNames(styles.component, styles[size], styles[theme], styles[iconPlacement], {
    [styles.hasBorder]: border,
    [styles.hasIcon]: hasIcon,
  });
  const type = clickHandler ? 'button' : url ? 'link' : 'static';
  const content = (
    <>
      <span className={styles.text}>{text}</span>
      {hasIcon && <ButtonIcon icon={icon} offsetX={iconOffsetX} offsetY={iconOffsetY}></ButtonIcon>}
    </>
  );

  if (type === 'link') {
    return (
      <motion.a
        whileTap={{ scale: 0.9 }}
        className={componentClass}
        href={url}
        target={target}
        rel="noopener noreferrer">
        {content}
      </motion.a>
    );
  } else if (type === 'button') {
    return (
      <motion.button whileTap={{ scale: 0.9 }} className={componentClass} onClick={clickHandler}>
        {content}
      </motion.button>
    );
  }

  return (
    <motion.div whileTap={{ scale: 0.9 }} className={componentClass}>
      {content}
    </motion.div>
  );
};

export default Button;
