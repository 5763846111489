import React from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import styles from './SuggestAreaSelection.module.scss';

import { checkSuggestAreaSelection } from 'store/restaurants/selectors';
import { useTranslation } from 'features/translator';

const SuggestAreaSelection = () => {
  const suggestAreaSelection = useSelector(checkSuggestAreaSelection);
  const translation = useTranslation();

  return (
    <motion.div
      className={styles.component}
      initial={suggestAreaSelection ? 'active' : 'inactive'}
      animate={suggestAreaSelection ? 'active' : 'inactive'}
      variants={{
        active: { height: 'auto', opacity: 1 },
        inactive: { height: 0, opacity: 0 },
      }}>
      <div className={styles.wrapper}>
        <p className={styles.text}>{translation.get('lunchPage', 'suggestAreaSelectionLabel')}</p>
      </div>
    </motion.div>
  );
};

export default SuggestAreaSelection;
