import { createContext } from 'react';

import { RoutePaths } from './types';

export interface MotionRouterContextProps {
  isAnimating: boolean;
  setIsAnimating: (to: boolean) => void;
  routePaths: RoutePaths;
  setRoutePaths: (to: RoutePaths) => void;
}

export const MotionRouterContext = createContext<MotionRouterContextProps | null>(null);
