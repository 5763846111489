import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import data, { dataPersistConfig } from './data/reducer';
import restaurants, { restaurantsPersistConfig } from './restaurants/reducer';
import foods, { foodsPersistConfig } from './foods/reducer';
import areas, { areasPersistConfig } from './areas/reducer';
import date from './date/reducer';
import system, { systemPersistConfig } from './system/reducer';
import translator, { translatorPersistConfig } from './translator/reducer';

const rootReducer = combineReducers({
  data: persistReducer(dataPersistConfig, data),
  restaurants: persistReducer(restaurantsPersistConfig, restaurants),
  foods: persistReducer(foodsPersistConfig, foods),
  areas: persistReducer(areasPersistConfig, areas),
  date,
  system: persistReducer(systemPersistConfig, system),
  translator: persistReducer(translatorPersistConfig, translator),
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
