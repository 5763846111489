import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { Route as BaseRoute, RouteProps } from 'react-router-dom';
import { MotionRouterContext } from './MotionRouterContext';
import { Route } from './types';
import { useAnimation } from './use-animation';

export const MotionRoute: React.FunctionComponent<RouteProps> = ({ children, ...props }) => {
  const context = useContext(MotionRouterContext);
  const animation = useAnimation();

  return (
    <BaseRoute {...props}>
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={{
          initial: () => animation.getInitial(),
          enter: () => animation.getEnter(),
          exit: ({ newRoute }: { newRoute: Route }) => animation.getExit(newRoute),
        }}
        onAnimationStart={() => context?.setIsAnimating(true)}
        onAnimationComplete={() => context?.setIsAnimating(false)}
        transition={{ type: 'spring', mass: 1, stiffness: 300, damping: 100 }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}>
        {children}
      </motion.div>
    </BaseRoute>
  );
};
