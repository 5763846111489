import { Language } from 'features/translator';

export const SET_ACTIVE_LANGUAGE: string = '[translator] Set Active Language';

export const setActiveLanguage = (language: Language) => ({
  type: SET_ACTIVE_LANGUAGE,
  payload: {
    activeLanguage: language,
  },
});
