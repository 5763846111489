import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MotionRouterContext } from './MotionRouterContext';
import { useRoutes } from './use-routes';

interface Props {
  to: string;
  className?: string;
}

export const MotionLink: React.FunctionComponent<Props> = ({ to, className, children }) => {
  const { currentRoute } = useRoutes();
  const context = useContext(MotionRouterContext);

  return (
    <Link
      to={{
        pathname: to,
        state: {
          oldRoute: currentRoute,
        },
      }}
      className={className}
      style={{ pointerEvents: context?.isAnimating ? 'none' : 'auto' }}>
      {children}
    </Link>
  );
};
