import React from 'react';

import analytics from 'utils/analytics';
import Accordion from 'components/Accordion';

interface Props {
  question: string;
  answer: string;
}

const FAQItem: React.FunctionComponent<Props> = ({ question, answer }) => {
  return <Accordion title={question} text={answer} analyticsCallback={analytics.openFAQ} />;
};

export default FAQItem;
