export function detectFirstTab(e: KeyboardEvent) {
  if (e.key === 'Tab') {
    const htmlEl = document.querySelector('html');

    if (htmlEl !== null) {
      htmlEl.classList.add('has-user-tabbing');
      window.removeEventListener('keydown', detectFirstTab);
    }
  }
}

const a11y = (): void => {
  window.addEventListener('keydown', detectFirstTab);
};

export default a11y;
