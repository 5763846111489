import { format, parse, getWeek, compareAsc } from 'date-fns';
import { fi, enUS, sv } from 'date-fns/locale';
import { Language } from 'features/translator';

export interface IWeek {
  number: number;
  dates: string[];
}

export type WeekContext = 'this' | 'next' | 'number';

export const getCurrentDate = (): string => {
  return getDateStringFromObject(new Date());
};

export const getDateStringFromObject = (date: Date): string => {
  return format(date, 'EEEE dd.MM.');
};

export const getDateObjectFromString = (date: string): Date => {
  return parse(date, 'EEEE dd.MM.', new Date());
};

export const getShortDisplayDate = (date: Date | string, language: Language): string => {
  let dateObj;

  if (typeof date === 'string') {
    dateObj = getDateObjectFromString(date);
  } else {
    dateObj = date;
  }

  switch (language) {
    case 'fi': {
      return getFinnishShortDisplayDate(dateObj);
    }
    case 'sv': {
      return getSwedishShortDisplayDate(dateObj);
    }
    case 'en': {
      return getEnglishShortDisplayDate(dateObj);
    }
  }
};

export const getFinnishShortDisplayDate = (date: Date): string => {
  return format(date, 'cccccc dd.MM.', { locale: fi });
};

export const getSwedishShortDisplayDate = (date: Date): string => {
  return format(date, 'ccc dd.MM.', { locale: sv });
};

export const getEnglishShortDisplayDate = (date: Date): string => {
  return format(date, 'ccc dd.MM.', { locale: enUS });
};

export const getLongDisplayDate = (date: Date | string, language: Language): string => {
  let dateObj;

  if (typeof date === 'string') {
    dateObj = getDateObjectFromString(date);
  } else {
    dateObj = date;
  }

  switch (language) {
    case 'fi': {
      return getFinnishLongDisplayDate(dateObj);
    }
    case 'sv': {
      return getSwedishLongDisplayDate(dateObj);
    }
    case 'en': {
      return getEnglishLongDisplayDate(dateObj);
    }
  }
};

export const getFinnishLongDisplayDate = (date: Date): string => {
  return format(date, 'cccc dd.MM.', { locale: fi });
};

export const getSwedishLongDisplayDate = (date: Date): string => {
  return format(date, 'cccc dd.MM.', { locale: sv });
};

export const getEnglishLongDisplayDate = (date: Date): string => {
  return format(date, 'cccc dd.MM.', { locale: enUS });
};

// export const getWeekLabel = (week: IWeek, today: string): string => {
//   const thisWeek = getWeek(getDateObjectFromString(today), { weekStartsOn: 1 });

//   if (week.number === thisWeek) {
//     return 'Tämä viikko';
//   } else if (week.number === thisWeek + 1) {
//     return `Ensi viikko`;
//   } else {
//     return `Viikko ${week.number}`;
//   }
// };

export const getWeekContext = (week: IWeek, today: string): WeekContext => {
  const thisWeek = getWeek(getDateObjectFromString(today), { weekStartsOn: 1 });

  if (week.number === thisWeek) {
    return 'this';
  } else if (week.number === thisWeek + 1) {
    return 'next';
  } else {
    return 'number';
  }
};

export const findSelectedWeekIndex = (selectedDate: string, weeks: IWeek[]): number => {
  const defaultWeekIndex = 0;
  let selectedWeek = defaultWeekIndex;

  weeks.forEach((week, index) => {
    if (week.dates.includes(selectedDate)) {
      selectedWeek = index;
    }
  });

  return selectedWeek;
};

export const isSameDay = (a: string, b: string): boolean => {
  return a === b;
};

export const isLater = (a: string, b: string): boolean => {
  const dateA = getDateObjectFromString(a);
  const dateB = getDateObjectFromString(b);
  return compareAsc(dateA, dateB) > 0;
};

export const isSameDayOrLater = (a: string, b: string): boolean => {
  return isSameDay(a, b) || isLater(a, b);
};

export const bundleDatesIntoWeeks = (dates: string[]): IWeek[] => {
  return dates.reduce((weeks, currentDate: string, currentIndex) => {
    const weekOfDate = getWeekNumber(dates[currentIndex]);
    const weekOfPreviousDate = currentIndex > 0 ? getWeekNumber(dates[currentIndex - 1]) : false;

    if (!weekOfPreviousDate || weekOfDate > weekOfPreviousDate) {
      weeks.push({ number: weekOfDate, dates: [currentDate] });
    } else {
      weeks[weeks.length - 1].dates.push(currentDate);
    }

    return weeks;
  }, [] as IWeek[]);
};

export const getWeekNumber = (date: string): number => {
  const dateObj = getDateObjectFromString(date);
  return getWeek(dateObj, { weekStartsOn: 1 });
};
