import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getSelectedDate } from 'store/date/selectors';
import { IMenu } from './Menu';
import { IMenuData } from './Restaurant';
import { isSameDay } from 'utils/date';

export function useMenus(menuData?: IMenuData) {
  const selectedDate = useSelector(getSelectedDate);
  const [menus, setMenus] = useState(getMenuByDate(menuData, selectedDate));

  useEffect(() => {
    setMenus(getMenuByDate(menuData, selectedDate));
  }, [selectedDate, menuData]);

  return { menus };
}

const getMenuByDate = (menuData?: IMenuData, date?: string): IMenu[] => {
  if (!menuData || !date) {
    return [];
  }

  return menuData.menus.filter((menu: IMenu) => isSameDay(menu.date, date));
};
