import { useLocation } from 'react-router-dom';
import { LocationWithOldRoute } from './types';

export function useRoutes() {
  const location: LocationWithOldRoute = useLocation();
  const { oldRoute } = location.state || {};
  const { pathname: currentRoute } = location || {};

  return {
    oldRoute,
    currentRoute,
  };
}
