import React from 'react';
import styles from './Highlight.module.scss';

import { ReactComponent as ChevronRight } from './assets/chevron-right.svg';

interface IHighlight {
  title: string;
  text: string;
  imageUrl: string;
  linkText?: string;
}

const Highlight: React.FunctionComponent<IHighlight> = ({ title, text, imageUrl, linkText = 'Lue lisää' }) => {
  return (
    <div className={styles.component}>
      <div className={styles.image} style={{ backgroundImage: `url(${imageUrl})` }} />
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.text}>{text}</p>
        <div className={styles.link}>
          <span className={styles.linkText}>{linkText}</span> <ChevronRight />
        </div>
      </div>
    </div>
  );
};

export default Highlight;
