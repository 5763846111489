import React from 'react';

const SwedishContent = () => {
  return (
    <>
      <p>
        Svinnmat, ekologiskt, Rättvisemärkt, veganskt – vi på UniCafe är kända för att möjliggöra smarta och hållbara
        val. Vi tillreder mat som är snäll både mot plånboken och miljön, som det är lätt att njuta av och som du kan
        äta med gott samvete. Vi visar vägen till framtidens studentmat och vi är stolta över det.
      </p>
      <p>
        Vårt framgångsrecept är att göra mat som gör gott. Enligt vår filosofi ska varenda portion vara så ansvarsfull,
        inhemsk och hälsosam som möjligt, och dessutom ska det smaka gott! Etiska värden, smak och pris är inte varandra
        uteslutande, om man vet vad man gör. Från baljhavre till syrsor och andra nya råvaror – vi letar hela tiden
        nyfiket efter nyheter som kan glädja våra kunder. På vår meny finns allt från moderna klassiker till
        överraskande smaker från stora världen.
      </p>
      <p>
        Våra dörrar står öppna för alla i staden. Så samla dina vänner runt ett bord och ta dig en välförtjänt paus i
        stressen. Det går ju inte att rädda världen på tom mage!
      </p>
      <p>
        <a href="https://unicafe.fi/sv/mat/" target="_blank" rel="noopener noreferrer">
          Läs mera på vår webbplats
        </a>
      </p>
    </>
  );
};

export default SwedishContent;
